import ReactGA from 'react-ga';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { differenceInDays } from 'date-fns';
import confetti from 'canvas-confetti';
import Bluebird from 'bluebird';

import { getUrl, IDENTIFIERS } from 'helpers/urlsHelper';
import ProductCard from '@lib/UserProfile/components/Card/Card';
import * as tsClient from '@tsClient';

import formatParticipants from '@helpers/formatParticipants';
import BroadcastContainer from '@components/BroadcastContainer';
import { Asset, Creator, HandbookStep, Media, NoodleProductTypes, Price, Product, ProductType } from '@typings/graphql-models';
import { NOODLE_CREATOR_ID, NOODLE_CREATOR_SLUG, SELF_ORIGIN } from '@configuration/client';
import { CardType } from '@lib/UserProfile/types';
import Package from '@components/Icons/Package';
import Banner from '@components/Banner';
import { useIsMobile, usePrevious, useToast, useUser } from '@hooks';
import useNoodleApi from '@hooks/useNoodleApi';
import { DefinitelyFunctionReturn } from '@typings/utility';
import {
  isConsultationProduct,
  isInPersonProduct,
  isLiteProduct,
  isLiveSessionProduct,
  isSubscriptionProduct,
} from '@helpers/helper';
import CommentParticipants from '@components/Message/CommentParticipants';
import { m } from 'framer-motion';
import XCircle from '@components/Icons/XCircle';
import Info from '@components/Icons/Info';
import ChatsCircle from '@components/Icons/ChatsCircle';
import UserCircle from '@components/Icons/UserCircle';
import UsersThree from '@components/Icons/UsersThree';
import LockOpen from '@components/Icons/LockOpen';
import FunnelSimple from '@components/Icons/FunnelSimple';
import Broadcast from '@components/Icons/Broadcast';
import Buttons from '@components/Buttons';
import ProgressIndicator from '@components/ProgressIndicator';
import useCanEditNoodle from '@hooks/useCanEditNoodle';
import { JobType, useJobContext } from '@providers/Jobs';
import { JobStatus } from '@providers/Jobs/Context';
import DashboardFTUEModal from '@components/Dashboards/DashboardFTUEModal';
import CreateBroadcastModal from '@modals/CreateEditBroadcastModal';
import { mixpanelTrack } from '@providers/Mixpanel';
import useDismissedMessages from '@providers/Messages/useDismissedMessages';
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import EditButton from '@components/EditButton';
import ProductTypeContainer from '@lib/UserProfile/components/ProductTypeContainer';
import Book from '@components/Icons/Book';
import NewConversationWithCreator from '@lib/NewConversationWithCreator';
import UserImage from '@components/UserImage';
import { useIsUserInitialized, useUserProfile } from '@providers/Auth';
import HandbookPdfContainer from '@lib/UserProfile/components/HandbookPdf/HandbookPdfContainer';
import { isFeaturedHandbook, isHandbookProduct } from '@helpers/isProductType';
import Noodle from '@components/Icons/Noodle';
import StartPostButton from '@components/StartPostButton';
import FilterChip from '@components/FilterChip';
import Chips from '@components/Chips';
import * as ApiModels from '@typings/api-models';
import SpeakerSimpleHigh from '@components/Icons/SpeakerSimpleHigh';
import SpeakerSimpleSlash from '@components/Icons/SpeakerSimpleSlash';
import ServiceCard from '@components/ServiceCard';
import TextEllipsisDetails from '@components/TextEllipsisDetails';
import flatten from 'lodash/flatten';
import removeNullish from '@helpers/removeNullish';
import LanguageSwitch from '@/components/LanguageSwitch';
import TranslationContext from '@/providers/TranslationProvider/TranslationContext';
import s from './CreatorBySlug.module.scss';

import HeroLinks from './HeroLinks';

type Participant = NonNullable<Parameters<typeof CommentParticipants>[0]['participants']>[number];

type ThisCreator = Pick<Creator, 'id' | 'name' | 'slug' | 'oneLiner' | 'countryCode' | 'businessCategory'> & {
  lastSeen?: string;
  image?: Pick<Asset, 'url'> | null;
  person?: {
    image?: { url: string } | null;
    id: string;
  } | null;
};

type Comments = Array<
  Pick<ApiModels.Message, 'id' | 'title' | 'text' | 'isDeleted' | 'createdAt' | 'type'> & {
    parent?:
      | (Pick<ApiModels.Message, 'id'> & {
          owner?: {
            name?: string | null;
            image?: { url: string } | null;
          } | null;
        })
      | null;
    owner?: {
      name?: string | null;
      image?: { url: string } | null;
    } | null;
    tags: {
      id: string;
      name: string;
    }[];
    medias: Pick<Media, 'id' | 's3OriginId' | 'vimeoThumbnailUrl' | 'vimeoUri' | 'type'>[];
    children: Array<{
      owner?: {
        id: string;
        name?: string | null;
        image?: { url: string } | null;
      } | null;
    }>;
  }
>;

type ThisProduct =
  & Pick<ApiModels.Product,
    | 'id'
    | 'slug'
    | 'title'
    | 'includesBroadcasts'
    | 'isActive'
  >
  & {
    comments?: Comments;
    image?: Pick<Asset, 'url'> | null;
    prices: Array<Pick<ApiModels.Price, 'id' | 'price' | 'isActive'>>;
    productTypes: Pick<ProductType, 'title' | 'noodleProductType' | 'slug'>[];
    initiateWorkflowCTAs: Array<Pick<ApiModels.InitiateWorkflowCTA, 'id' | 'title' | 'CTA' | 'description'>>;
  }
  & Parameters<typeof isSubscriptionProduct>[0]
  & Parameters<typeof ServiceCard>[0]['product']
  & Parameters<typeof ProductTypeContainer>[0]['bytes'][number]
  & Parameters<typeof HandbookPdfContainer>[0]['bytes'][number];

type ThisProductType =
  & Pick<ProductType, 'id' | 'title' | 'noodleProductType'>
  & Parameters<typeof ProductTypeContainer>[0]['type'];

type Props = {
  data: {
    artist?: ThisCreator | null;
    broadcastTiers: Parameters<typeof CreateBroadcastModal>[0]['broadcastTiers'];
    products: ThisProduct[];
    productTypes: ThisProductType[];
    featuredProduct?:
      | (Pick<Product, 'id' | 'title' | 'slug'> & {
          handbookSteps?: Array<Pick<HandbookStep, 'id'>>;
          image?: Pick<Asset, 'url'> | null;
          prices?: Array<Pick<Price, 'id'>>;
        })
      | null;
  };
  isWidget: boolean;
};

type LatestBroadcastType = {
  howMany?: number;
  comments: Comments | undefined;
  creatorSlug: string;
  broadcastSlug: string | null;
};

const LatestBroadcast: React.FC<LatestBroadcastType> = ({ howMany, comments, creatorSlug, broadcastSlug }) => {
  if (comments) {
    return (
      <BroadcastContainer
        key={'Broadcasts'}
        title={'Broadcasts'}
        allBroadcastLink={getUrl(IDENTIFIERS.CREATOR, { creatorSlug, tab: 'Broadcasts' })}
        creatorSlug={creatorSlug}
        howMany={howMany}
        isList
        shouldNavigate={false}
        data={comments.map(comment => ({
          createdAt: comment.createdAt,
          creatorSlug,
          enableLink: true,
          hideDate: false,
          id: comment.id,
          inContainer: true,
          isNew: differenceInDays(new Date(), new Date(comment.createdAt)) < 2,
          media: comment.medias?.[0],
          messageId: comment?.id || null,
          numComments: comment.children.length,
          participants: formatParticipants(comment),
          productSlug: broadcastSlug,
          text: comment.text,
          title: comment.title || 'Untitled Broadcast',
        }))}
      />
    );
  }
  return null;
};

const CONFETTI_DISMISS_KEY = 'creator-slug-confetti';
const FTUE_MODAL_DISMISS_KEY = 'creator_FTUE_modal';

const CreatorBySlug: React.FC<Props> = ({ data, isWidget }) => {
  const [isUnlockClicked, setIsUnlockClicked] = useState(false);
  const [productData, setProductData] = useState(data);
  const [canComment, setCanComment] = useState(false);
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [broadcastsOnly, setBroadcastsOnly] = useState(true);
  const [showCreatorsDetails, setShowCreatorsDetails] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [
    videoUrl,
    // setVideoUrl
  ] = useState<string | null>(
    // 'https://media.graphassets.com/audHneFSqmGLQ9INyPkJ'
    null,
  );

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && !isMuted) {
      videoRef.current.currentTime = 0;
    }
  }, [isMuted, videoRef]);

  const [user] = useUser();
  const isUserInitialized = useIsUserInitialized();
  const router = useRouter();
  const { language, setLanguage } = useContext(TranslationContext);

  const [selectedChip, setSelectedChip] = useState<string | null>('View All');

  const { dismissMessage, isMessageDismissed, isInitialized } = useDismissedMessages();
  const profile = useUserProfile();

  const userId = user?.id;

  const prevCustomerView = usePrevious(router.query.customerView);

  const { jobs } = useJobContext();

  const inProgressPhotoUploadJobs = jobs.filter(j => j.type === JobType.UPLOAD_PROFILE_PHOTO && j.status === JobStatus.IN_PROGRESS).length;

  const prevInProgressPhotoUploadJobs = usePrevious(inProgressPhotoUploadJobs);

  const isDisabled = Boolean(jobs.find(j => j.type === JobType.CREATE_DEFAULT_PRODUCTS && j.status === JobStatus.IN_PROGRESS));

  const [isCreateBroadcastModalVisible, setIsCreateBroadcastModalVisible] = useState(false);
  const products = useMemo(() => productData?.products || [], [productData]);
  const broadcastTiers = useMemo(() => data?.broadcastTiers || [], [data]);

  const [members, setMembers] = useState<null | {
    total: number;
    items: Participant[];
  }>(null);
  const addToast = useToast();
  const [isGuideModalOpen, setIsGuideModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ThisProduct | null>(null);
  const productTypes = productData?.productTypes || [];

  const creator: ThisCreator | null = useMemo(() => productData?.artist || null, [productData]);
  const isArtist = creator?.businessCategory === 'musician-artist';

  const setTab = (tab: string): void => {
    const routerBasePath = router.asPath.split('?');
    const queryParamsStr = new URLSearchParams({ tab }).toString();
    const urlWithNewTab = `${routerBasePath[0]}?${queryParamsStr}`;
    setSelectedChip('View All');
    router.push(urlWithNewTab, undefined, { shallow: true });
  };

  const canEdit = useCanEditNoodle({ creator });

  const broadcast = products.find(product => product.includesBroadcasts);

  const broadcastComments = broadcast?.comments?.filter(
    comment => comment.type === ApiModels.MessageType.PaidBroadcast || comment.type === ApiModels.MessageType.FreeBroadcast
      || comment.type === ApiModels.MessageType.CommunityPost,
  );
  const magicProduct = productData?.featuredProduct;

  const membershipProducts = products.filter(isSubscriptionProduct);
  const liteProducts = products.filter(isLiteProduct);
  const onlineSessionProducts = products.filter(isLiveSessionProduct);
  const inPersonSessionProducts = products.filter(isInPersonProduct);
  const consultationProducts = products.filter(isConsultationProduct).filter(p => p.isActive);

  const consultationProduct: ThisProduct | null = consultationProducts.length > 0
    ? {
      handbookSteps: [],
      id: consultationProducts[0].id,
      includesBroadcasts: false,
      initiateWorkflowCTAs: [],
      isActive: true,
      prices: flatten(consultationProducts.map(p => p.prices)) as ThisProduct['prices'],
      productTypes: consultationProducts[0].productTypes,
      showOnOneLink: true,
      slug: consultationProducts[0].productTypes?.[0].slug,
      title: 'Book a consultation',
    }
    : null;

  const isConfettiDismissed = isMessageDismissed(CONFETTI_DISMISS_KEY);

  const connectProducts = [
    consultationProduct,
    ...inPersonSessionProducts,
    ...liteProducts,
    ...onlineSessionProducts,
    ...membershipProducts,
  ].filter(removeNullish).filter(
    product => canEdit || product.prices.find(price => price.isActive) || product.initiateWorkflowCTAs?.length > 0,
  );

  const activeConnectProducts = connectProducts.filter(product => product.isActive)
    || connectProducts.filter(product => product.initiateWorkflowCTAs?.length > 0);

  const enableServicesTab = connectProducts.filter(product => product.isActive).length > 0;
  const allHandbooks = products.filter(p => p.isActive).filter(isHandbookProduct);
  const featuredHandbooks = allHandbooks.filter(isFeaturedHandbook).reverse();

  const { getData: getMembersFn } = useNoodleApi(tsClient.getMembers);
  const { getData: refetchProductData, fetchingState: productDataFetchingState } = useNoodleApi(tsClient.getCreatorProducts);

  const {
    getData: getCommentPermissionsFn,
    fetchingState: { isFetching: isCheckingCanComment },
  } = useNoodleApi(tsClient.getCommentPermissions);

  const tab = deserializeQueryStringItem(router.query.tab, {
    defaultValue: creator?.slug === NOODLE_CREATOR_SLUG ? 'Chat' : 'Services',
    type: 'string',
  });

  const queryTag = router.query.tag;

  const membershipProductId = membershipProducts[0]?.id;
  useEffect(() => {
    const checkCanComment = async (): Promise<void> => {
      if (membershipProductId && user && user.id && !user.isAnonymous) {
        const { data: commentData } = await getCommentPermissionsFn({ productId: membershipProductId });
        setCanComment(commentData?.canComment || false);
      } else {
        setCanComment(false);
      }
    };
    checkCanComment();
  }, [membershipProductId, user, getCommentPermissionsFn]);

  useEffect(() => {
    if (!videoUrl) {
      setShowCreatorsDetails(true);
    }
  }, [videoUrl, setShowCreatorsDetails]);

  useEffect(() => {
    if (Array.isArray(queryTag)) {
      setSelectedChip(queryTag[0]);
    }
    if (typeof queryTag === 'string') {
      setSelectedChip(queryTag);
    }
  }, [queryTag]);

  useEffect(() => {
    products.forEach(product => {
      ReactGA.plugin.execute('ec', 'addImpression', {
        brand: creator?.name,
        id: product.id,
        name: product.title,
        price: product?.prices[0]?.price,
      });
    });
  }, [productData, creator?.name, products]);

  useEffect(() => {
    if (prevCustomerView && router.query.customerView !== prevCustomerView) {
      addToast(useToast.ToastTypeVariants.INFO, router.query.customerView === 'true' ? 'This is your customer view.' : 'You are now in edit mode.');
    }
  }, [addToast, router.query.customerView, prevCustomerView]);

  useEffect(() => {
    mixpanelTrack('View creator slug page');
  }, []);

  useEffect(() => {
    if (canEdit) {
      setBroadcastsOnly(false);
    }
  }, [canEdit]);

  useEffect(() => {
    if (userId && userId === creator?.person?.id && isInitialized && !isConfettiDismissed) {
      dismissMessage(CONFETTI_DISMISS_KEY);
      dismissMessage(FTUE_MODAL_DISMISS_KEY);
      Bluebird.delay(750).then(() => {
        setIsGuideModalOpen(true);
        confetti({
          disableForReducedMotion: true,
          origin: { y: 0.9 },
          particleCount: 100,
          spread: 70,
        });
      });
      mixpanelTrack('First Creator Slug Page View');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isConfettiDismissed, creator?.person?.id, userId, setIsGuideModalOpen]);

  useEffect(() => {
    const getMembersCall = async (): Promise<void> => {
      if (creator) {
        const newData = await Promise.all(
          ['all'].map(async key => {
            const keyData = await getMembersFn({ creatorSlug: creator.slug, filter: key, page: 1, perPage: 10, search: '', withNames: true });
            return {
              ...keyData,
              key,
            };
          }),
        );
        const items: NonNullable<DefinitelyFunctionReturn<typeof getMembersFn>['data']>['items'] = [];
        let total = 0;
        newData.forEach(item => {
          items.push(...(item?.data?.items || []));
          total += item.data?.numItems || 0;
        });
        setMembers({ items: items.map(i => ({ ...i, color: i?.primaryColour?.hex, logo: i.image?.url })), total });
      }
    };

    getMembersCall();
  }, [creator, getMembersFn]);

  const setCreateBroadcastModalVisible = (isVisible: boolean): void => {
    setIsCreateBroadcastModalVisible(isVisible);
  };

  const openCreateBroadcastModal = (): void => {
    mixpanelTrack('Edit Mode - message your community');
    setIsCreateBroadcastModalVisible(true);
  };

  const refetchData = useCallback(async (): Promise<void> => {
    const { data: newData } = await refetchProductData({ creatorSlug: data.artist?.slug || '' });
    setProductData({
      ...newData,
      artist: newData?.artist?.[0] || null,
      broadcastTiers: data.broadcastTiers || [],

      productTypes: newData?.productTypes[0]?.productTypes || [],
      products: newData?.products || [],
    });
    setSelectedProduct(newData?.products?.find(p => p.id === selectedProduct?.id) || null);
    setIsCreateBroadcastModalVisible(false);
  }, [data?.artist?.slug, data?.broadcastTiers, refetchProductData, selectedProduct?.id]);

  useEffect(() => {
    // Only refetch when the number of in progress photo uploads changes from nonzero to zero
    if (inProgressPhotoUploadJobs === 0 && (prevInProgressPhotoUploadJobs || 0) > 0) {
      refetchData();
    }
  }, [inProgressPhotoUploadJobs, prevInProgressPhotoUploadJobs, refetchData]);

  const isMobile = useIsMobile();

  const handleGuideClose = (isOpen: boolean): void => {
    setIsGuideModalOpen(isOpen);
  };

  const handleEditProfileClick = (): void => {
    router.push(getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_EDIT_BUSINESS_DETAILS));
  };

  const broadcastTags = Array.from(new Set(broadcastComments?.map(comment => comment?.tags?.map(tag => tag.name)).flat()));

  const filteredBroadcastsData = broadcastComments?.filter(comment => comment.tags?.map(tag => tag.id).includes(selectedChip as string));

  const broadcastData = selectedChip === 'View All' ? broadcastComments : filteredBroadcastsData;

  return (
    <>
      {isGuideModalOpen && <DashboardFTUEModal setModal={handleGuideClose} isArtist={isArtist} />}
      {isCreateBroadcastModalVisible && creator && (
        <CreateBroadcastModal
          setIsVisible={setCreateBroadcastModalVisible}
          onPublish={refetchData}
          broadcastTiers={broadcastTiers}
          isCommunityPost={!canEdit}
          creatorSlug={creator.slug}
          productSlug={broadcast?.slug || null}
        />
      )}
      <div className={s['creator-page__container']}>
        {canEdit && <Banner onClick={() => router.push(getUrl(IDENTIFIERS.DASHBOARD))} text="Go to dashboard" icon={<Noodle />} />}
        <div className={s['creator-page__wrapper']}>
          {!isWidget && creator && (
            <>
              <div
                className={s['creator-page__header']}
                style={
                  (videoUrl && {
                    backgroundImage: `
                    var(--primary-gradient)
                 `,
                  }) || {
                    backgroundColor: 'var(--color-gray-0)',
                    borderBottom: '1px solid var(--color-gray-25)',
                    borderLeft: isMobile ? 'none' : '1px solid var(--color-gray-25)',
                    borderRight: isMobile ? 'none' : '1px solid var(--color-gray-25)',
                    borderTop: isMobile ? 'none' : '1px solid var(--color-gray-25)',
                    boxShadow: 'var(--shadow-lg)',
                  }
                }
              >
                <LanguageSwitch className={s.lang} currentLanguage={language} onChange={setLanguage} />
                {videoUrl && !showCreatorsDetails && (
                  <>
                    <video className={s.video} ref={videoRef} playsInline autoPlay muted={isMuted} loop>
                      <source src={videoUrl} type="video/mp4" />
                    </video>
                    <button onClick={() => setIsMuted(!isMuted)} className={s.audio}>
                      {isMuted ? <SpeakerSimpleSlash weight="fill" /> : <SpeakerSimpleHigh weight="fill" />}
                    </button>
                    <div className={s.videoGradient} />
                  </>
                )}
                <div className={s[`creator-page__header-author${!videoUrl ? '-text-only' : ''}`]}>
                  {showCreatorsDetails && <UserImage color="var(--color-primary)" size={80} url={creator.person?.image?.url} name={creator.name} />}
                  <div>
                    <h1 data-translate="false">{creator.name}</h1>
                    {showCreatorsDetails && (
                      <div
                        className={s[`creator-page__header-details${!videoUrl ? '-text-only' : ''}`]}
                        style={(tab === 'Chat' && isWidget && { display: 'none' }) || {}}
                      >
                        {creator.oneLiner && <TextEllipsisDetails text={creator.oneLiner} />}
                        {canEdit && <EditButton onClick={handleEditProfileClick} label="Edit your profile" />}
                        {creator?.slug && <HeroLinks creatorSlug={creator?.slug} canEdit={canEdit} />}
                      </div>
                    )}
                    {videoUrl && (
                      <Buttons className={s.learnMore} isWrapper onClick={() => setShowCreatorsDetails(!showCreatorsDetails)}>
                        {showCreatorsDetails
                          ? (
                            <>
                              <XCircle weight="fill" size={16} /> Hide
                            </>
                          )
                          : (
                            <>
                              <Info weight="fill" size={16} /> Learn more
                            </>
                          )}
                      </Buttons>
                    )}
                  </div>
                </div>
                <div className={videoUrl ? s.categories : s['categories-text-only']}>
                  {!canEdit && (
                    <label>
                      <input
                        type="radio"
                        name="tab"
                        checked={tab === 'Chat'}
                        onChange={() => {
                          setTab('Chat');
                        }}
                      />
                      <ChatsCircle weight={tab === 'Chat' ? 'fill' : 'regular'} size={24} />
                      <m.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1, transition: { delay: 1, duration: 0.5, type: 'spring' } }}
                        className={s.active}
                      />
                      Chat
                    </label>
                  )}
                  {((canEdit && enableServicesTab) || activeConnectProducts.length > 0) && (
                    <label>
                      <input type="radio" name="tab" checked={tab === 'Services'} onChange={() => setTab('Services')} />
                      <Package weight={tab === 'Services' ? 'fill' : 'regular'} size={24} />
                      Services
                    </label>
                  )}
                  {broadcastComments && (
                    <label>
                      <input type="radio" name="tab" checked={tab === 'Broadcasts'} onChange={() => setTab('Broadcasts')} />
                      <UsersThree weight={tab === 'Broadcasts' ? 'fill' : 'regular'} size={24} />
                      Community
                    </label>
                  )}
                  {allHandbooks.length > 0 && (
                    <label>
                      <input type="radio" name="tab" checked={tab === 'Bytes'} onChange={() => setTab('Bytes')} />
                      <Book weight={tab === 'Bytes' ? 'fill' : 'regular'} size={24} />
                      Library
                    </label>
                  )}

                  {!canEdit && creator && (
                    <label>
                      <input
                        type="radio"
                        name="tab"
                        onChange={() => {
                          setIsProfileClicked(true);
                          router.push(getUrl(IDENTIFIERS.USER_PROFILE, { creatorSlug: creator.slug }));
                        }}
                      />
                      {(isProfileClicked && <ProgressIndicator size={24} />)
                        || (profile && <UserImage size={24} url={profile.image?.url} name={profile.name} color={profile.primaryColour?.hex} />) || (
                        <UserCircle weight="regular" size={24} />
                      )}
                      Profile
                    </label>
                  )}
                </div>
              </div>
            </>
          )}
          {isWidget && creator && (
            <>
              <LanguageSwitch className={s.langWidget} currentLanguage={language} onChange={setLanguage} />
              <div className={s.categoriesWidget}>
                <label>
                  <input
                    type="radio"
                    name="tab"
                    checked={tab === 'Chat'}
                    onChange={() => {
                      setTab('Chat');
                    }}
                  />
                  <ChatsCircle weight={tab === 'Chat' ? 'fill' : 'regular'} size={24} />
                  <m.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 1, duration: 0.5, type: 'spring' } }} className={s.active} />
                  Chat
                </label>
                {activeConnectProducts.length > 0 && (
                  <label>
                    <input type="radio" name="tab" checked={tab === 'Services'} onChange={() => setTab('Services')} />
                    <Package weight={tab === 'Services' ? 'fill' : 'regular'} size={24} />
                    Services
                  </label>
                )}
                {broadcastComments && (
                  <label>
                    <input type="radio" name="tab" checked={tab === 'Broadcasts'} onChange={() => setTab('Broadcasts')} />
                    <UsersThree weight={tab === 'Broadcasts' ? 'fill' : 'regular'} size={24} />
                    Community
                  </label>
                )}
                {allHandbooks.length > 0 && (
                  <label>
                    <input type="radio" name="tab" checked={tab === 'Bytes'} onChange={() => setTab('Bytes')} />
                    <Book weight={tab === 'Bytes' ? 'fill' : 'regular'} size={24} />
                    Library
                  </label>
                )}
                <label>
                  <input
                    type="radio"
                    name="tab"
                    onChange={() => {
                      setIsProfileClicked(true);
                      router.push(getUrl(IDENTIFIERS.USER_PROFILE, { creatorSlug: creator.slug }));
                    }}
                  />
                  {(isProfileClicked && <ProgressIndicator size={24} />)
                    || (profile && <UserImage size={24} url={profile.image?.url} name={profile.name} />) || <UserCircle weight="regular" size={24} />}
                  Profile
                </label>
              </div>
            </>
          )}
          <div className={s[`creator-page__content-wrapper${(tab === 'Chat' && '-chat') || ''}`]}>
            {isWidget && creator && magicProduct && (
              <ProductCard
                className={s['creator-page__magic-product']}
                type={CardType.Plan}
                link={`${SELF_ORIGIN}${getUrl(IDENTIFIERS.PRODUCT_PURCHASE, {
                  creatorSlug: creator.slug,
                  priceId: magicProduct.prices?.[0]?.id,
                  productSlug: magicProduct?.slug,
                })}`}
                isFree={true}
                inContainer={false}
                image={magicProduct?.image?.url}
                title={magicProduct?.title || ''}
                steps={magicProduct?.handbookSteps?.length}
                price={null}
              />
            )}
            <div className={s.connect}>
              {creator && isUserInitialized && (
                <div style={{ display: tab === 'Chat' ? 'unset' : 'none' }}>
                  <NewConversationWithCreator isAnonymous={!user} isEmbedded creatorSlug={creator.slug} />
                </div>
              )}
              {tab === 'Services' && creator && (
                <div className={s.chats}>
                  {productDataFetchingState.isFetching
                    ? (
                      <ProgressIndicator isCentered />
                    )
                    : (
                      connectProducts
                      // dont show inactive products
                        .filter(product => product.isActive)
                        .filter(product => product.showOnOneLink)
                      // dont show products without active prices or initiateWorkflowCTAs
                        .filter(product => product.prices.find(price => price.isActive) || product.initiateWorkflowCTAs?.length > 0)
                        .filter(product => !(isSubscriptionProduct(product) && !product.isActive))
                        .map((p) => (
                          <ServiceCard
                            key={p.id}
                            product={p}
                            creator={creator}
                            canEdit={canEdit}
                            to={(isConsultationProduct(p) && NOODLE_CREATOR_ID === creator.id && "https://noodle.law/meet")
                              || isConsultationProduct(p) && getUrl(IDENTIFIERS.PRODUCT_TYPE, { creatorSlug: creator.slug, productTypeSlug: p.slug })
                              || getUrl(IDENTIFIERS.PRODUCT, { creatorSlug: creator.slug, productSlug: p.slug })}
                            newTab={isConsultationProduct(p) && NOODLE_CREATOR_ID === creator.id}
                          />
                        ))
                    )}
                </div>
              )}
              {tab === 'Services' && creator && featuredHandbooks.length > 0 && (
                <div className={s.bytes}>
                  <HandbookPdfContainer bytes={featuredHandbooks} creatorSlug={creator.slug} tab={tab} />
                </div>
              )}
              {tab === 'Bytes' && creator && (
                <div className={s.bytes}>
                  {productTypes
                    .filter(type => type.noodleProductType === NoodleProductTypes.Handbook)
                    .map((type, index) => {
                      const bytes = allHandbooks.filter(handbook => handbook.productTypes?.[0]?.title === type.title);
                      if (bytes.length === 0) return null;
                      return (
                        <m.div
                          key={type.title}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1 * (index + 1), type: 'spring' }}
                        >
                          <ProductTypeContainer type={type} bytes={bytes} creatorSlug={creator.slug} canEdit={canEdit} />
                        </m.div>
                      );
                    })}
                </div>
              )}
            </div>
            {tab === 'Broadcasts' && creator && !canEdit && !isCheckingCanComment && membershipProducts.length > 0 && (
              <>
                {canComment
                  ? (
                    <StartPostButton onClick={openCreateBroadcastModal} />
                  )
                  : (
                    <>
                      {members && (
                        <CommentParticipants
                          participants={members?.items || []}
                          maxLength={5}
                          totalParticipants={members.total}
                          userLogoSize={24}
                          isJoin
                        />
                      )}
                      <Buttons
                        isFourth
                        onClick={() => {
                          router.push(getUrl(IDENTIFIERS.PRODUCT, { creatorSlug: creator.slug, productSlug: membershipProducts[0].slug }));
                          setIsUnlockClicked(true);
                        }}
                        isShimmering={isUnlockClicked}
                        iconBefore={<LockOpen weight="fill" size={20} />}
                      >
                      Unlock full community access
                      </Buttons>
                    </>
                  )}
                <div className={s.chips}>
                  <FilterChip
                    label={`${creator.name}'s broadcasts only`}
                    onClick={() => setBroadcastsOnly(!broadcastsOnly)}
                    isSelected={broadcastsOnly}
                    Icon={FunnelSimple}
                  />
                  {tab === 'Broadcasts' && !!broadcastTags.length && (
                    <Chips
                      data={[{ id: 'View All', label: 'View All' }, ...broadcastTags.map(tag => ({ id: tag, label: tag || '' }))]}
                      selectedItem={selectedChip}
                      onItemClick={e => {
                        setSelectedChip(e);
                        router.push(
                          {
                            pathname: router.pathname,
                            query: { ...router.query, tab: 'Broadcasts', tag: e },
                          },
                          undefined,
                          { shallow: true },
                        );
                      }}
                    />
                  )}
                </div>
              </>
            )}
            {tab === 'Broadcasts' && creator && broadcastComments && broadcastComments.length > 0 && (
              <LatestBroadcast
                howMany={isMobile ? 4 : 6}
                comments={broadcastData?.filter(comment => (broadcastsOnly ? comment.owner?.name === creator.name : true))}
                creatorSlug={creator.slug}
                broadcastSlug={broadcast?.slug || null}
              />
            )}
            {tab === 'Broadcasts' && broadcastComments && broadcastComments.length === 0 && (
              <div className={classNames(s.noBroadcasts, 'body-sm')}>
                {canEdit ? 'You have no broadcasts yet. Message your community and get started!' : 'No broadcasts yet. Stay tuned!'}
              </div>
            )}
            {tab === 'Broadcasts' && canEdit && (
              <Buttons isSecondary onClick={openCreateBroadcastModal} disabled={isDisabled} className={s.messageCommunity}>
                <Broadcast weight="fill" size={20} />
                Message Your Community
              </Buttons>
            )}
            {isDisabled && <p className={s.chats__message}>We’re still getting this set up. Just a moment...</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatorBySlug;
