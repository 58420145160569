import React from 'react';
import CustomLink from '@components/CustomLink';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import ProductIcon from '@components/ProductIcon';
import * as ApiModels from '@typings/api-models';
import LocalCurrencyConversion from '@components/LocalCurrencyConversion';
import s from './HandbookPdfContainer.module.scss';

type ThisProduct = Pick<ApiModels.Product,
  | 'id'
  | 'isActive'
  | 'showOnOneLink'
  | 'slug'
  | 'title'
> & {
  handbookSteps: Array<Pick<ApiModels.HandbookStep,
    | 'id'
  > & {
    photos: Array<Pick<ApiModels.Asset, 'mimeType'>>;
  }>
  prices: Array<Pick<ApiModels.Price,
    | 'currency'
    | 'isActive'
    | 'price'
  >>;
};

type ContainerProps = {
  bytes: Array<ThisProduct>;
  creatorSlug: string;
  tab: string;
};

const HandbookPdfContainer = ({ bytes, creatorSlug, tab }: ContainerProps): JSX.Element => {
  const [clickedByte, setClickedByte] = React.useState<string | null>(null);
  return (
    <>
      {bytes
        .filter(b => b.isActive)
        .sort(a => (a.isActive ? -1 : 1))
        .map(product => {
          if ((product.showOnOneLink && tab === 'Services') || tab === 'Bytes') {
            const activePrice = product.prices.filter(prod => prod.isActive)[0];
            return (
              <CustomLink
                to={getUrl(IDENTIFIERS.PRODUCT, { creatorSlug, productSlug: product.slug })}
                onClick={() => setClickedByte(product.id)}
                className={product.isActive ? s.productType : s.productTypeDisabled}
                key={product.id}
                style={(clickedByte === product.id && { backgroundColor: 'var(--color-gray-25)' }) || {}}
              >
                <div className={s.icon}>
                  <ProductIcon
                    size={24}
                    noodleProductType={ApiModels.NoodleProductTypes.Handbook}
                    mimeType={product.handbookSteps.length && product.handbookSteps[0].photos ? product.handbookSteps[0].photos[0]?.mimeType : null}
                  />
                </div>
                <div className={s['productType__info-container']}>
                  <p>
                    <strong>{product.title}</strong>
                  </p>
                  <div className={s['productType__info-subTitle']}>
                    <span>
                      {activePrice?.price === 0 || !activePrice
                        ? (
                          `Try for free!`
                        )
                        : (
                          <LocalCurrencyConversion
                            price={activePrice.price}
                            currency={activePrice.currency}
                            noStyles
                            roundUp
                            progressConfig={{ isInline: true, size: 10 }}
                          />
                        )}
                    </span>
                  </div>
                </div>
                {!product.isActive && <div className={s.inactive}>Inactive</div>}
              </CustomLink>
            );
          }
          return null;
        })}
    </>
  );
};

export default HandbookPdfContainer;
