import React, { ReactElement, useState, useEffect, Dispatch, SetStateAction, useContext } from 'react';
import { m, AnimationProps } from 'framer-motion';
import classNames from 'classnames';
import Image from 'next/legacy/image';

import SvgNoodle from '@components/Icons/Noodle';
import { getVideoData, getMessageThread, getProductTiers, updateMessage } from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import Link from '@components/CustomLink';
import AudioPlayer from '@components/AudioPlayer';
import { Product, Media } from '@typings/graphql-models';
import * as ApiModels from '@typings/api-models';
import CommentParticipants from '@components/Message/CommentParticipants';
import { useIsMobile, useUser } from '@hooks';
import getMediaUrl from '@helpers/getMediaUrl';
import Lock from '@components/Icons/Lock';
import Tooltip from '@components/Tooltip';
import UserImage from '@components/UserImage';
import { formatDistanceToNow } from 'date-fns';
import PencilSimple from '@components/Icons/PencilSimple';
import CreateEditBroadcastModal from '@modals/CreateEditBroadcastModal';
import TeamsContext from '@providers/Teams/TeamsContext';
import PushPin from '@components/Icons/PushPin';
import PushPinSlash from '@components/Icons/PushPinSlash';
import ChatsCircle from '@components/Icons/ChatsCircle';
import ProgressIndicator from '@components/ProgressIndicator';
import { type Icon as IconType } from '@phosphor-icons/react';
import SpeakerSimpleHigh from '@components/Icons/SpeakerSimpleHigh';
import s from './BroadcastPreview.module.scss';

type ThisMessage = Omit<NonNullable<Parameters<typeof CreateEditBroadcastModal>[0]['broadcast']>, 'product'>
  & Parameters<typeof CreateEditBroadcastModal>[0]['broadcast']
  & Pick<ApiModels.Message, 'id' | 'isPinned' | 'type'>
  & {
    owner?: Pick<ApiModels.Person, 'id' | 'name'> & {
      image?: { url: string } | null;
      primaryColour?: { hex: string } | null;
    } | null;
    product: {
      creator: Pick<ApiModels.Creator, 'id' | 'personId' | 'name'>;
    }
  };

type ParticipantType = {
  id: string;
  name?: string | null;
  logo?: string | null;
  color?: string | null;
};

type BroadcastPreviewProps = {
  createdAt: string;
  creatorSlug: string | null;
  productSlug: string | null;
  messageId: string | null;
  enableLink: boolean;
  isNew: boolean;
  media?: Pick<Media, 'id' | 's3OriginId' | 'vimeoThumbnailUrl' | 'vimeoUri' | 'type'> | null;
  handbook?: Pick<Product, 'id' | 'slug'> & {
    image?: {
      url: string;
    } | null;
  };
  numComments: number;
  participants: ParticipantType[];
  text?: string | null;
  title?: string | null;
  isPopout?: boolean;
  setAutoPlayId?: Dispatch<SetStateAction<string | null>>;
  autoPlayId?: string | null;
  id: string;
  hideDate?: boolean;
};

const Icon = ({ IconAsset, isSmall }: { IconAsset: IconType; isSmall?: boolean }): ReactElement => {
  const transition: AnimationProps['transition'] = { duration: 2, repeat: Infinity, repeatType: 'reverse', type: 'spring' };
  return (
    <div
      className={s.playWrapper}
      style={
        (isSmall && {
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          transform: 'scale(0.5) translateX(24px) translateY(24px)',
          width: '100%',
        })
        || {}
      }
    >
      <m.div className={s.circle} animate={{ scale: [1.2, 1.8] }} transition={transition} />
      <m.div className={s.circle} animate={{ scale: [1.1, 1.4] }} transition={transition} />

      <div className={s.playCircle}>
        <IconAsset size={40} weight="fill" color={'var(--color-primary)'} />
      </div>
    </div>
  );
};

const MotionCover = ({ children, isPressed, isHovered }: { children: React.ReactNode; isPressed: boolean; isHovered: boolean }): ReactElement => (
  <m.div className={s['image-container--motion']} animate={{ scale: (isPressed && 1) || (isHovered ? 1.1 : 1) }}>
    {children}
  </m.div>
);

const BroadcastPreview = ({
  createdAt,
  creatorSlug,
  productSlug,
  messageId,
  enableLink,
  handbook,
  isNew,
  media,
  numComments,
  participants,
  text,
  title,
  isPopout = false,
  setAutoPlayId,
  autoPlayId,
  id,
  hideDate,
}: BroadcastPreviewProps): ReactElement => {
  const [user] = useUser();
  const [mediaType, setMediaType] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [productTitle, setProductTitle] = useState<string | undefined | null>('');
  const [isBroadcastModalOpen, setBroadcastModalOpen] = useState(false);
  const [message, setMessage] = useState<ThisMessage | null>(null);
  const [isUpdatingPinState, setIsUpdatingPinState] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  const { getData: getMessageThreadFn } = useNoodleApi(getMessageThread);
  const { data: tiers, getData: getTiersFn } = useNoodleApi(getProductTiers);
  const { getData: updateMessageFn } = useNoodleApi(updateMessage);

  const { teamUserId, creatorId } = useContext(TeamsContext);

  const isMobile = useIsMobile();
  const link = enableLink && creatorSlug && productSlug && messageId ? `/${creatorSlug}/${productSlug}/thread/${messageId}` : '#';

  useEffect(() => {
    const checkUserAccess = async (): Promise<void> => {
      if (creatorSlug && productSlug && messageId) {
        const messageResponse = await getMessageThreadFn({
          creatorSlug,
          messageId,
          page: 1,
          perPage: 1,
          productSlug,
        });
        if (messageResponse.data) {
          const { children: newChildren, numChildren, ...newMessage } = messageResponse.data;
          setIsLocked(messageResponse.data.isContentHidden);
          setProductTitle(messageResponse.data.product.upsellPrice?.product?.title || messageResponse.data.product.title);
          setMessage(newMessage);
          getTiersFn({ id: newMessage.product.id || '' });
        }
      }
    };

    checkUserAccess();
  }, [getMessageThreadFn, getTiersFn, creatorSlug, productSlug, messageId]);

  useEffect(() => {
    setIsPinned(message?.isPinned || false);
  }, [message]);

  useEffect(() => {
    const baseMediaType = media?.type?.substring(0, media.type.indexOf('/')) || '';
    if (baseMediaType !== 'video' && handbook) {
      setMediaType('handbook');
    } else {
      setMediaType(baseMediaType);
    }
  }, [media, handbook, setMediaType]);

  const { data: videoData, getData: getVideoDataFn } = useNoodleApi(getVideoData);

  useEffect(() => {
    if (media && media.vimeoUri) {
      getVideoDataFn({ vimeoUri: media.vimeoUri });
    }
  }, [media, getVideoDataFn]);

  const isVideo = mediaType === 'video';

  const detectLogoSize = (): number => {
    if (isPopout && isVideo) return 20;
    if (isPopout && !isVideo) return 24;
    return 24;
  };

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const updatePinState = async (): Promise<void> => {
    setIsUpdatingPinState(true);
    if (productSlug && creatorSlug && message?.id) {
      await updateMessageFn({ ...message, creatorSlug, isPinned: !message?.isPinned, messageId: message.id, productSlug });
    }
    setIsPinned(!isPinned);
    setIsUpdatingPinState(false);
  };

  useEffect(() => {
    if (!isMobile) {
      if (autoPlayId === id) {
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    }
  }, [isVideo, autoPlayId, id, isMobile]);

  const canEdit = user?.id !== undefined && (user?.id === message?.owner?.id || teamUserId === message?.owner?.id);
  const isCreator = creatorId === message?.product.creator.id;

  const isMessageFromCreator = message?.owner?.id === message?.product.creator.personId;

  const isShortText = mediaType === '' && text?.length && text?.length < 400;

  return (
    <>
      <m.div
        initial={{ filter: 'drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))', opacity: 0 }}
        whileTap={{ scale: 1 }}
        animate={{ opacity: 1 }}
        whileHover={{
          filter: 'drop-shadow(0px 16px 20px rgba(0, 0, 0, 0.1))',
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onMouseEnter={() => {
          setIsHovered(true);
          if (setAutoPlayId && id) {
            setAutoPlayId(id);
          }
        }}
        onTapStart={() => setIsPressed(true)}
        onTapCancel={() => setIsPressed(false)}
        className={classNames(s.motion, {
          [s['motion--is-text-short']]: isShortText,
          [s['motion--is-text']]: mediaType === '',
          [s['motion--is-popout']]: isPopout && !isVideo,
          [s['motion--is-video-popout']]: isPopout && isVideo,
        })}
      >
        {!isPopout && message?.owner?.name && (
          <div className={isMessageFromCreator ? s.author : s.authorCustomer}>
            <UserImage size={24} url={message?.owner?.image?.url} name={message.owner.name} color={message?.owner?.primaryColour?.hex} />
            <div>
              <p>{message.owner.name}</p>
              {!hideDate && <small>{formatDistanceToNow(new Date(createdAt), { addSuffix: true })}</small>}
            </div>
          </div>
        )}
        {isLocked && productTitle && !canEdit && (
          <Tooltip text={`Available to ${productTitle} members`} alignment="right" className={s.lockTooltip}>
            <div className={s['container-content--lock-icon']}>
              <Lock weight="fill" color="white" />
            </div>
          </Tooltip>
        )}
        {isCreator && (
          <div className={classNames(s.topRightContainer, { [s.pinButton]: canEdit })}>
            <button onClick={updatePinState}>
              {isUpdatingPinState
                ? (
                  <div>
                    <ProgressIndicator size={14} />
                  </div>
                )
                : (
                  <div>
                    {isPinned
                      ? <PushPinSlash weight="fill" color="white" />
                      : <PushPin weight="fill" color="white" />
                    }
                  </div>
                )
              }
            </button>
          </div>
        )}
        {canEdit && (
          <div className={s.topRightContainer}>
            <button onClick={() => setBroadcastModalOpen(true)}>
              <div>
                <PencilSimple weight="fill" color="white" />
              </div>
            </button>
          </div>
        )}
        <Link
          className={classNames(s.container, {
            [s['container--is-popout']]: isPopout && !isVideo,
            [s['container--is-video-popout']]: isPopout && isVideo,
            [s['container--is-audio']]: mediaType === 'audio',
          })}
          to={link}
          style={(canEdit && { boxShadow: '0 0 0 4px var(--color-gray-25)' }) || undefined}
        >
          <div>
            {isPopout && mediaType !== 'image' && mediaType !== 'handbook' && (
              <div
                className={classNames(s['noodle-logo'], {
                  [s['noodle-logo--white']]: isVideo,
                })}
              >
                <SvgNoodle />
              </div>
            )}

            <div
              className={classNames(s['image-container'], {
                [s['container-content--locked']]: isLocked,
                [s['image-container--small']]: isPopout && !isVideo,
                [s['image-container--video']]: isPopout && isVideo,
                [s['image-container--audio']]: isPopout && mediaType === 'audio',
                [s['image-container--text']]: mediaType === '',
              })}
            >
              <>
                {media && mediaType === 'image' && (
                  <MotionCover isHovered={isHovered} isPressed={isPressed}>
                    {isPopout && (
                      <div className={classNames(s['noodle-logo'], [s['noodle-logo--white']], [s['image-container__noodle-logo']])}>
                        <SvgNoodle />
                      </div>
                    )}
                    <Image src={getMediaUrl(media)} layout="fill" objectFit="cover" alt="" width={300} className={s.blurredImageCover} />
                    <Image src={getMediaUrl(media)} layout="fill" objectFit="contain" alt="" width={300} height={isPopout ? 170 : 300} />
                  </MotionCover>
                )}

                {mediaType === 'audio' && !isPopout && (
                  <MotionCover isHovered={isHovered} isPressed={isPressed}>
                    <Icon IconAsset={SpeakerSimpleHigh} />
                  </MotionCover>
                )}

                {media && mediaType === 'audio' && isPopout && (
                  <MotionCover isHovered={isHovered} isPressed={isPressed}>
                    <div style={{ marginTop: '10px', pointerEvents: 'none', width: '100%' }}>
                      <AudioPlayer
                        isSmall={true}
                        audio={{
                          url: getMediaUrl(media),
                        }}
                      />
                    </div>
                  </MotionCover>
                )}

                {mediaType === 'handbook' && handbook?.image?.url && (
                  <MotionCover isHovered={isHovered} isPressed={isPressed}>
                    {isPopout && (
                      <div className={classNames(s['noodle-logo'], [s['noodle-logo--white']], [s['image-container__noodle-logo']])}>
                        <SvgNoodle/>
                      </div>
                    )}
                    <Image src={handbook?.image?.url} layout="responsive" objectFit="cover" alt="" />
                  </MotionCover>
                )}

                {mediaType === 'video' && (
                  <>
                    <MotionCover isHovered={isHovered} isPressed={isPressed}>
                      {!!videoData && (
                        <div className={s['video-box']}>
                          <video
                            ref={videoRef}
                            loop
                            muted
                            autoPlay={!isLocked || !autoPlayId || (autoPlayId && autoPlayId === id) || isMobile}
                            controls={false}
                            playsInline
                            poster={media?.vimeoThumbnailUrl || undefined}
                          >
                            <source src={videoData?.play?.progressive?.find(p => p.width === 240)?.link || videoData?.play?.progressive?.[0]?.link} />
                            <track kind="captions" />
                          </video>
                        </div>
                      )}
                    </MotionCover>
                  </>
                )}

                {mediaType === 'handbook' && (
                  <MotionCover isHovered={isHovered} isPressed={isPressed}>
                    {isPopout && (
                      <div className={classNames(s['noodle-logo'], [s['noodle-logo--white']], [s['image-container__noodle-logo']])}>
                        <SvgNoodle/>
                      </div>
                    )}
                    {media?.vimeoThumbnailUrl && (
                      <Image src={media.vimeoThumbnailUrl} layout="fill" objectFit="cover" alt="" width={300} height={300} />
                    )}
                  </MotionCover>
                )}
              </>
            </div>

            {isPopout && isVideo && <div className={s.overlay} />}
            <div
              className={classNames(s.labels, {
                [s['labels--is-video-popout']]: isPopout && isVideo,
              })}
            >
              <div className={s['title-is-new']}>
                {isNew && <div className={classNames('caption', s.newLabel)}>New</div>}
                <h2
                  className={mediaType === '' ? 'heading-sm' : 'caption'}
                  style={(mediaType === '' && { color: 'var(--color-primary)', textShadow: 'unset' }) || undefined}
                >
                  {title || 'Broadcast'}
                </h2>
              </div>
              {mediaType === '' && !isShortText && !isPopout && <p className={classNames('body-sm', s['broadcast-text'])}>{text}</p>}
            </div>
          </div>
          <div className={s['broadcast-comments']} style={(mediaType === '' && { backgroundImage: 'unset', color: 'var(--color-gray-100)' }) || {}}>
            {numComments > 0 && (
              <div className={s.commentsPill}>
                <ChatsCircle weight="fill" color="var(--color-gray-100)" size={12} />
                <p className={s.numComments}>{numComments}</p>
              </div>
            )}
          </div>
          <div
            className={s['broadcast-participants']}
            style={(mediaType === '' && { backgroundImage: 'unset', color: 'var(--color-gray-100)' }) || {}}
          >
            {!participants.length && !isPopout && <p className="caption">Be the first to comment on this!</p>}
            {participants.length > 0 && (
              <CommentParticipants
                participants={participants}
                userLogoSize={detectLogoSize()}
                remainingParticipantsAsNumber
                noLabel
                maxLength={2}
                style={{
                  color: (isPopout && isVideo) || mediaType === '' ? 'var(--color-gray-0)' : undefined,
                }}
              />
            )}
          </div>
        </Link>
      </m.div>
      {isBroadcastModalOpen && message && (
        <CreateEditBroadcastModal
          setIsVisible={setBroadcastModalOpen}
          creatorName={message.product.creator.name}
          creatorSlug={creatorSlug || ''}
          productSlug={productSlug}
          onPublish={async () => {
            setBroadcastModalOpen(false);
          }}
          broadcastTiers={tiers}
          broadcast={message}
          isCommunityPost={message.type === ApiModels.MessageType.CommunityPost}
        />
      )}
    </>
  );
};

export default BroadcastPreview;
