import React, { ReactElement, FC } from 'react';
import MusicNotesSimple from '@components/Icons/MusicNotesSimple';
import Ticket from '@components/Icons/Ticket';
import TiktokLogo from '@components/Icons/TiktokLogo';
import YoutubeLogo from '@components/Icons/YoutubeLogo';
import FacebookLogo from '@components/Icons/FacebookLogo';
import InstagramLogo from '@components/Icons/InstagramLogo';
import Link from '@components/Icons/Link';

import { ContentLibraryTypes } from '@typings/graphql-models';
import SvgDeezerLogo from '@components/Icons/DeezerLogo';
import SvgAppleMusicLogo from '@components/Icons/AppleMusicLogo';
import SvgSpotifyLogo from '@components/Icons/SpotifyLogo';

type Props = {
  mediaType: string;
};

const detectMediaSvg = (mediaType: string): JSX.Element => {
  switch (mediaType) {
  case ContentLibraryTypes.Spotify:
    return <SvgSpotifyLogo />;
  case ContentLibraryTypes.YouTube:
    return (
      <YoutubeLogo size={24} weight="fill" />
    );
  case ContentLibraryTypes.Facebook:
    return (
      <FacebookLogo size={24} weight="fill" />
    );
  case ContentLibraryTypes.Instagram:
    return (
      <InstagramLogo size={24} weight="fill" />
    );
  case ContentLibraryTypes.AppleMusic:
    return <SvgAppleMusicLogo />;
  case ContentLibraryTypes.Deezer:
    return <SvgDeezerLogo />;
  case ContentLibraryTypes.Music:
    return <MusicNotesSimple size={24} />;
  case ContentLibraryTypes.Tickets:
    return <Ticket size={24} />;
  case ContentLibraryTypes.TikTok:
    return <TiktokLogo size={24} weight="fill" />;
  case ContentLibraryTypes.Other:
    return <Link size={24} weight="fill" />;
  default:
    return <></>;
  }
};

const HeroIcon: FC<Props> = ({ mediaType }): ReactElement => detectMediaSvg(mediaType);

export default HeroIcon;
