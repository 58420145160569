import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Descendant } from 'slate';
import InputComposer from '@components/InputComposer';
import CommentMessage from '@components/Message/CommentMessage';
import { NoodleProductTypes } from '@typings/graphql-models';
import BackButton from '@components/BackButton';
import PaginationObserver from '@components/PaginationObserver';
import { nanoid } from 'nanoid';
import { JobType, useJobContext } from '@providers/Jobs';
import JobsContainer from '@providers/Jobs/JobsContainer';
import Buttons from '@components/Buttons';
import ProductIcon from '@components/ProductIcon';
import UserImage from '@components/UserImage';
import isInPopoverWidget from '@helpers/isInPopoverWidget';
import getConversationPurchases from '@tsClient/conversations/getConversationPurchases';
import useNoodleApi from '@hooks/useNoodleApi';
import updateFulfillment from '@tsClient/conversations/updateFulfillment';
import { withTimezone } from '@format/datetime';
import useHistory from '@providers/History';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import Modal from '@components/Modal';
import { useIsMobile } from '@hooks';
import CustomLink from '@components/CustomLink';
import CheckBox from '@components/FormFields/CheckBox';
import { useUser } from '@providers/Auth';
import NoodleAI from '@components/Icons/NoodleAI';
import CaretCircleDown from '@components/Icons/CaretCircleDown';
import CaretRight from '@components/Icons/CaretRight';
import { NOODLE_CREATOR_PERSON_ID, YOUR_AI_CREATOR_PERSON_ID } from '@configuration/client';
import { PWAContext } from '@providers/PWA';
import { uniqBy } from 'lodash';
import Spacer from '@components/Spacer';
import { m as motion } from 'framer-motion';
import isInIframe from '@helpers/isInIframe';
import classNames from 'classnames';
import ProgressIndicator from '@components/ProgressIndicator';
import * as ApiModels from '@typings/api-models';
import { useRouter } from 'next/router';
import { SIZES } from '@styles/media';
import s from './ChatWithExpert.module.scss';

type ChatMessage = {
  id: string;
  createdAt: string;
  title: string | null;
  text: string | null;
  richText?: {
    html: string;
  } | null;
  type?: ApiModels.MessageType | null;
  owner?: {
    id: string;
    image?: {
      url: string;
    } | null;
    primaryColour?: {
      hex: string;
    } | null;
    name?: string | null;
  } | null;
  isAIGenerated: boolean;
  isDeleted: boolean;
};

type Props = {
  conversationSid: string | null;
  messageData: ChatMessage[];
  creator: {
    id: string;
    isAiEnabled: boolean;
    slug: string;
    countryCode?: string | null;
    personId?: string | null;
    lastSeen?: string | null;
    name?: string | null;
    primaryColour?: {
      hex: string;
    } | null;
    person?: {
      id: string;
      image: {
        url: string;
      };
    } | null;
  } | null;
  customer?: {
    id: string;
    name?: string | null;
    image?: {
      url: string;
    } | null;
    primaryColour?: {
      hex: string;
    } | null;
  } | null;
  rootMessageId: string | null;
  currentPage: number;
  isTheCreator: boolean;
  isPostingComment: boolean;
  isAllMessages: boolean;
  onSendMessage: (data: {
    text?: { children: Descendant[] }[];
    medias?: { id: string }[];
    handbooks?: { id: string }[];
    attachments?: ApiModels.CreateMessageAttachment[];
  }) => Promise<void>;
  onDeleteMessage: (id: string) => void;
  onFetchNextPage: (rootMessageId: string, currentPage: number, currentMessages: ChatMessage[]) => Promise<boolean>;
  purchases: Awaited<ReturnType<typeof getConversationPurchases>> | null;
  refetchPurchases: () => Promise<void>;
  isAIEnabled: boolean;
  setAIEnabled: (enabled: boolean) => Promise<void>;
  isEmbedded?: boolean;
  isAITyping: boolean;
  isPaginationEnabled: boolean;
  isInitialFetch?: boolean;
  isAnonymousChat?: boolean;
  reloadConversation: () => Promise<void>;
  isOnUserProfile?: boolean;
};

const FREE_CHAT_SIGN_UP_URL = 'start-chatting';
const SCROLL_DISTANCE_CONSIDERED_TO_BE_BOTTOM = 10;

const ChatWithExpert: React.FC<Props> = ({
  conversationSid,
  currentPage,
  messageData,
  isOnUserProfile,
  isTheCreator,
  creator,
  customer,
  refetchPurchases,
  rootMessageId,
  onSendMessage,
  onDeleteMessage,
  onFetchNextPage,
  isAllMessages,
  isPostingComment,
  purchases,
  isAITyping,
  isAIEnabled: isAIEnabledOnConversation,
  setAIEnabled,
  isEmbedded,
  isPaginationEnabled,
  isInitialFetch,
  isAnonymousChat,
  reloadConversation,
}: Props) => {
  const [isMarkingAsDone, setIsMarkingAsDone] = useState<string>('');
  const allMessagesRef = useRef(isAllMessages);
  const [jobCorrelationId, setJobCorrelationId] = useState(nanoid());
  const [isAsync, setIsAsync] = useState(false);
  const [isFromSignUpFlow, setIsFromSignUpFlow] = useState(false);
  // state version is for iframe/"load more" button, ref is to stop handleFetchNextPage from firing again.
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const isFetchingNextPageRef = useRef(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user] = useUser();
  const mostRecentMessageRef = useRef<string | null>(null);
  const desiredScrollFromBottom = useRef<number | null>(null);
  const scrollAnchorRef = useRef<HTMLDivElement>(null);
  const { isInApp } = useContext(PWAContext);

  const isHiRes = useIsMobile(SIZES.xl);
  const isAiPreview = creator?.personId === YOUR_AI_CREATOR_PERSON_ID;
  const isCreatorSuccess = creator?.personId === NOODLE_CREATOR_PERSON_ID;
  const router = useRouter();

  const isOnDashboard = router.pathname.includes('dashboard') && !isHiRes;

  const { jobs, addJob } = useJobContext();
  const { history } = useHistory();
  const uploadJobs = jobs.filter(j => j.correlationId === jobCorrelationId);
  const inputComposerRef = useRef<HTMLDivElement>(null);

  const { getData: markPurchaseAsDone } = useNoodleApi(updateFulfillment);

  // Set the desiredScrollFromBottom to 0 here so make sure it's before the resize observer fires.
  const newMostRecentMessageId = messageData?.[0]?.id;
  if (newMostRecentMessageId && newMostRecentMessageId !== mostRecentMessageRef.current) {
    desiredScrollFromBottom.current = 0;
  }
  mostRecentMessageRef.current = newMostRecentMessageId ?? null;

  const handleAsyncSend = async ({
    attachments,
    text,
    handbooks = [],
  }: {
    text?: { children: Descendant[] }[];
    handbooks?: { id: string }[];
    attachments?: ApiModels.CreateMessageAttachment[];
  }): Promise<void> => {
    const sendFunction = async (overrideMediaIds?: { id: string }[]): Promise<void> => {
      await onSendMessage({ attachments, handbooks, medias: overrideMediaIds || [], text });
    };
    addJob({
      dependsOn: uploadJobs,
      function: sendFunction,
      metadata: {
        replyingTo: rootMessageId,
      },
      title: 'Chat Message',
      type: JobType.CHAT_MESSAGE,
    });
  };

  const handleSend = async ({
    attachments,
    text,
    handbooks = [],
  }: {
    text?: { children: Descendant[] }[];
    handbooks?: { id: string }[];
    attachments?: ApiModels.CreateMessageAttachment[];
  }): Promise<void> => {
    if (isAsync) {
      await handleAsyncSend({ attachments, handbooks, text });
    } else {
      await onSendMessage({ attachments, handbooks, medias: [], text });
    }
    setIsAsync(false);
    setJobCorrelationId(nanoid());
  };

  const handleFileChange = (files: unknown[]): void => {
    if (files.length > 0) {
      setIsAsync(true);
    } else {
      setIsAsync(false);
    }
  };

  const handleMediaChange = (medias: unknown[]): void => {
    setIsAsync(medias.length > 0);
  };

  const handleFetchNextPage = async (): Promise<void> => {
    if (isFetchingNextPageRef.current) {
      return;
    }

    if (rootMessageId) {
      isFetchingNextPageRef.current = true;
      setIsFetchingNextPage(true);
      const allMessagesFetched = await onFetchNextPage?.(rootMessageId, currentPage, messageData);
      allMessagesRef.current = allMessagesFetched;
      isFetchingNextPageRef.current = false;
      setIsFetchingNextPage(false);
    }
  };

  const markAsDone = async (id: string): Promise<void> => {
    setIsMarkingAsDone(id);
    if (conversationSid) {
      await markPurchaseAsDone({ conversationSid, isFulfilled: true, purchaseId: id });
      await refetchPurchases();
    }
    setIsMarkingAsDone('');
  };

  useEffect(() => {
    const scrollListener = (): void => {
      const fromBottom = document.body.scrollHeight - window.scrollY;
      desiredScrollFromBottom.current = Math.abs(window.innerHeight - fromBottom) < SCROLL_DISTANCE_CONSIDERED_TO_BE_BOTTOM ? 0 : fromBottom;
    };
    document.addEventListener('scroll', scrollListener);
    return () => document.removeEventListener('scroll', scrollListener);
  });

  useEffect(() => {
    if ((!isInIframe() || isInPopoverWidget()) && ResizeObserver) {
      const resizeObserver = new ResizeObserver(() => {
        if (desiredScrollFromBottom.current === 0) {
          scrollAnchorRef.current?.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
        } else if (desiredScrollFromBottom.current !== null) {
          const newScroll = document.body.scrollHeight - desiredScrollFromBottom.current;
          window.scrollTo(0, newScroll);
        }
      });

      resizeObserver.observe(document.body);
      return () => {
        resizeObserver.unobserve(document.body);
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (history.length > 1) {
      const lastUrlVisited = history.slice(-2)[0];
      setIsFromSignUpFlow(lastUrlVisited.includes(FREE_CHAT_SIGN_UP_URL));
    }
  }, [history]);

  const creatorColor = messageData.find(m => m.owner?.id === creator?.personId)?.owner?.primaryColour?.hex;
  const creatorImage = messageData.find(m => m.owner?.id === creator?.personId)?.owner?.image;

  const customerProducts: Array<{
    bookingTime?: string;
    id: string;
    name?: string | null;
    timezone?: string | null;
    type?: NoodleProductTypes | null;
    isSubscription: boolean;
  }> = useMemo(
    () => [
      ...(purchases?.personProducts?.map(p => ({
        bookingTime: p.booking?.startAt ?? undefined,
        id: p.id,
        isSubscription: false,
        name: p.price?.priceTitle || p.product?.title,
        timezone: p.product?.creator?.timezone,
        type: p.product?.productTypes?.[0]?.noodleProductType,
      })) || []),
      ...(purchases?.personSubscriptions?.map(ps => ({
        id: ps.id,
        isSubscription: true,
        name: ps.priceConnection?.priceTitle || ps.subscriptionModel?.product?.title,
        type: ps.subscriptionModel?.product?.productTypes?.[0]?.noodleProductType,
      })) || []),
    ],
    [purchases],
  );

  const customerImage = customer?.image;

  const canAddMessage = !isTheCreator || (isTheCreator && !creator?.isAiEnabled)
    || (isTheCreator && creator?.isAiEnabled && !isAIEnabledOnConversation);

  const canToggleAIEnabled = user?.id === NOODLE_CREATOR_PERSON_ID
    || (isTheCreator && creator?.isAiEnabled) // || (isTheCreator && creator.isAiEnabled && !isPayingCustomer)
    || (isTheCreator && !creator?.isAiEnabled && !isAIEnabledOnConversation)
    || (isTheCreator && !canAddMessage);

  const inputComposerSize = inputComposerRef.current?.clientHeight || 172;

  useEffect(() => {
    const wrapper = document.querySelector('#creator-ui-provider-style-wrapper');
    if (wrapper && isAiPreview) {
      wrapper.setAttribute('style', `--color-primary: ${customer?.primaryColour?.hex};`);
    }
  }, [customer?.primaryColour?.hex, isAiPreview]);

  const showCustomerProducts = isTheCreator
    && customerProducts.length > 0
    && !(customerProducts.length === 1
    && customerProducts.find(i => i.type === NoodleProductTypes.Handbook));

  return (
    <div className={s['container-embedded']}>
      <div
        className={s.top}
        {...(showCustomerProducts && {
          style: {
            borderRadius: '0 0 8px 8px',
          },
        })}
      >
        {jobs.length > 0 && <JobsContainer />}
        {(!isEmbedded || isTheCreator) && (
          <div
            className={s.userTop}
            {...(showCustomerProducts && {
              style: {
                borderBottom: 0,
              },
            })}
          >
            <BackButton
              backLink={isFromSignUpFlow && creator ? getUrl(IDENTIFIERS.CREATOR, { creatorSlug: creator.slug }) : undefined}
              fallBackLink={
                isTheCreator || isAiPreview ? getUrl(IDENTIFIERS.DASHBOARD) : getUrl(IDENTIFIERS.CREATOR, { creatorSlug: creator?.slug || '' })
              }
              title=""
            />
            <CustomLink
              className={s.topName}
              to={(!isAiPreview && (isTheCreator ? `/dashboard/members/${customer?.id}` : `/${creator?.slug}`)) || undefined}
            >
              {!isTheCreator && !isAiPreview && (
                <>
                  <UserImage url={creatorImage?.url} size={24} name={creator?.name} color={creatorColor} />
                  <strong>{creator?.name}</strong>
                  <CaretRight size={12} color="var(--color-gray-75)" />
                </>
              )}
              {(isTheCreator || isAiPreview) && customer && (
                <>
                  <UserImage
                    key={`${customer.name}-image`}
                    url={customerImage?.url}
                    size={24}
                    name={customer.name}
                    color={customer.primaryColour?.hex}
                  />
                  <strong style={(isAiPreview && { color: customer.primaryColour?.hex }) || undefined}>{customer.name || 'Anonymous'}</strong>
                </>
              )}
            </CustomLink>
            <div />
          </div>
        )}
        {showCustomerProducts && (
          <details className={s.showProducts}>
            <summary>
              <span>
                Purchased products
                <small>{customerProducts.length}</small>
              </span>
              <CaretCircleDown size={12} weight="fill" color="var(--color-gray-100)" />
            </summary>
            <ul className={s.products}>
              {customerProducts.map(i => (
                <li key={i.id}>
                  <p>
                    <ProductIcon size={12} noodleProductType={i.type} />
                    {i.name}
                    {i.bookingTime ? ':' : ''}
                    {i.bookingTime && <span>{withTimezone({ datetime: i.bookingTime, timezone: i.timezone })}</span>}
                  </p>
                  {!i.isSubscription && !i.bookingTime && (
                    <>
                      <Buttons isWrapper onClick={() => setIsModalOpen(true)}>
                        Mark as done
                      </Buttons>
                      {isModalOpen && (
                        <Modal title="Mark as done?" onClose={() => setIsModalOpen(false)}>
                          <div className={s.wrapperModal}>
                            <p className="body-md-bold">You’re about to end your paid chat with {customer?.name}</p>
                            <p className="body-sm">
                              They will be notified and asked for a review. They’ll still be able to message you, but their messages will now appear
                              in the ‘Leads’ section of your queue.
                            </p>
                            <Buttons
                              onClick={() => {
                                markAsDone(i.id);
                                setIsModalOpen(false);
                              }}
                              isSecondary
                              isFetching={isMarkingAsDone === i.id}
                              isFullWidth
                            >
                              Mark as done
                            </Buttons>
                          </div>
                        </Modal>
                      )}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </details>
        )}
      </div>
      <div className={s.mainContent}>
        <div ref={scrollAnchorRef} />
        <Spacer size={inputComposerSize} />
        {isAITyping && creator && (
          <>
            <CommentMessage
              creatorPersonId={creator.personId || ''}
              creatorSlug={creator.slug}
              canDeleteMessages={false}
              canPinMessages={false}
              canReplyToMessages={false}
              comment={{
                createdAt: new Date().toISOString(),
                id: 'responding',
                isDeleted: false,
                owner: isAiPreview
                  ? {
                    id: customer?.id || '',
                    image: customerImage,
                    name: customer?.name,
                    primaryColour: { hex: customer?.primaryColour?.hex || '' },
                  }
                  : {
                    id: creator.personId || '',
                    image: creatorImage,
                    name: creator.name,
                    primaryColour: { hex: creatorColor || '' },
                  },
                text: `AI Assistant is typing…`,
                title: '',
              }}
              isChatMessage={{
                isAutomated: true,
                isResponding: true,
              }}
            />
            <Spacer size={24} />
          </>
        )}
        {uniqBy(
          messageData.filter(m => (m.isAIGenerated ? m.text || m.richText?.html : true)),
          'id',
        ).map(
          (
            message, // this main-content div is styled column-reverse, pagination is actually from top
          ) => (
            <div key={message.id} className={classNames(s.message, s['message-embedded'])}>
              <CommentMessage
                creatorPersonId={creator?.personId || ''}
                creatorSlug={creator?.slug || ''}
                canDeleteMessages={user?.id === message.owner?.id}
                canPinMessages={false}
                canReplyToMessages={false}
                customerId={customer?.id}
                reloadConversation={reloadConversation}
                comment={
                  isAiPreview
                    ? {
                      ...message,
                      owner: {
                        id: message.owner?.id === creator?.personId ? customer?.id || '' : creator?.personId || '',
                        image: message.owner?.image === creatorImage ? customerImage : creatorImage,
                        name: message.owner?.name === creator?.name ? customer?.name : creator?.name,
                        primaryColour:
                            message.owner?.primaryColour?.hex === creatorColor
                              ? { hex: customer?.primaryColour?.hex || '' }
                              : { hex: creatorColor || '' },
                      },
                    }
                    : message
                }
                isChatMessage={{
                  isAutomated: message.isAIGenerated,
                }}
                onDeleteMessage={onDeleteMessage}
              />
            </div>
          ),
        )}
        {!allMessagesRef.current && messageData.length > 1 && isPaginationEnabled && (
          <>
            {isInIframe()
              ? (
                <PaginationObserver showSpinner={false} intersectTime={1000} isDisabled={isFetchingNextPage} loadFn={handleFetchNextPage} />
              )
              : (
                <motion.div className={s.pagination} initial={{ opacity: 0, y: -80 }} whileInView={{ opacity: 1, y: 16 }}>
                  <PaginationObserver showSpinner={true} intersectTime={1000} isDisabled={false} loadFn={handleFetchNextPage} />
                </motion.div>
              )}
          </>
        )}
        {messageData.length === 0 && isInitialFetch && (
          <div className={s.messagesLoader}>
            {isAnonymousChat
              ? (
                <>
                  {Boolean(isCreatorSuccess) && <><Spacer size={0} /><NoodleAI /></>}
                  {isCreatorSuccess ? 'Have questions?' : 'No messages yet.'}
                  <br />
                  {isCreatorSuccess ? 'Start a chat with the Noodle AI Assistant.' : `Start conversation with ${creator?.name}.`}
                </>
              )
              : (
                <ProgressIndicator />
              )}
          </div>
        )}
        {messageData.length === 0 && !isInitialFetch && (
          <div className={s.messagesLoader}>
            <UserImage size={48} name={creator?.name} isActive color={creator?.primaryColour?.hex} url={creator?.person?.image} />
            No messages yet.
            <br />
            Start conversation with {creator?.name}.
          </div>
        )}
      </div>
      <div
        className={
          (isOnUserProfile && s['inputComposerContainer-userProfile'])
          || (isOnDashboard && s['inputComposerContainer-dashboard'])
          || s.inputComposerContainer
        }
        ref={inputComposerRef}
        style={{
          paddingBottom: (isInApp && 72) || (isInPopoverWidget() && 60) || (!isEmbedded && 24) || undefined,
        }}
      >
        <div className={s.inputComposer}>
          {canToggleAIEnabled && (
            <div className={s.assistant}>
              <p>
                <NoodleAI />
                <span>
                  <strong>AI Assistant</strong>{' '}
                  {isAIEnabledOnConversation ? 'takes care of your responses. Turn off the switch to answer yourself.' : 'is off'}
                </span>
              </p>
              <CheckBox
                isSwitch
                values={{ isActive: isAIEnabledOnConversation }}
                hasFixedHeight={false}
                disabled={false}
                id="isActive"
                name="isActive"
                noFlex
                onChange={isEnabled => {
                  setAIEnabled(isEnabled !== 'true');
                }}
              />
            </div>
          )}
          {canAddMessage && (
            <InputComposer
              onSend={handleSend}
              isDisabled={!creator}
              placeholder="Type your message"
              isFetching={isPostingComment}
              showProTips={false}
              onChangeFiles={handleFileChange}
              onChangeMedia={handleMediaChange}
              showAddVideo
              showAddFormRequest={isTheCreator}
              showAddDocumentRequest={isTheCreator}
              showAddHandbook={isTheCreator}
              isLoomEnabled={isTheCreator}
              showAddAnyFile={true}
              showAddPaymentLink={isTheCreator}
              isAsync
              jobCorrelationId={jobCorrelationId}
              referenceId={rootMessageId || nanoid()}
              referenceType={'chat-message'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatWithExpert;
