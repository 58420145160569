import * as React from 'react';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import Link from 'components/CustomLink';
import { m } from 'framer-motion';
import Microphone from '@components/Icons/Microphone';
import VideoCamera from '@components/Icons/VideoCamera';
import LinkIcon from '@components/Icons/Link';
import Play from '@components/Icons/Play';
import { type Icon as IconType } from '@phosphor-icons/react';
import * as format from '@format';
import CommentParticipants from '@components/Message/CommentParticipants';
import s from './Card.module.scss';
import { CardType, AssetType } from '../../types';

export type CardProps = {
  type?: CardType;
  className?: string;
  image?: string | null;
  title?: string | null;
  isNew?: boolean;
  isFree?: boolean;
  isInLancher?: boolean;
  inContainer: boolean;
  link: string;
  newTab?: boolean;
  assets?: {
    type: AssetType;
    count?: number;
    minutes?: number;
  }[];
  participants?: {
    id: string;
    name?: string | null;
    logo?: string | null;
    color?: string | null;
  }[];
  timestamp?: string;
  duration?: string;
  price: { price: number; currency: string } | null;
  steps?: number;
  tags?: string[];
  category?: string | null;
  isInUserProfile?: boolean;
  isPurchased?: boolean;
};

const Card = ({
  image,
  title = '',
  assets,
  link,
  newTab = false,
  isNew,
  isFree,
  isInLancher,
  inContainer,
  type,
  timestamp,
  duration,
  price,
  steps,
  tags,
  className,
  participants,
  category,
  isInUserProfile,
  isPurchased,
}: CardProps): JSX.Element => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isPressed, setIsPressed] = React.useState(false);
  const imageHeight = (isInLancher && 115) || (type === CardType.Broadcast && 360) || 136;

  const priceLabel = (): string => {
    if (!price) return '';
    if (price.price !== 0) {
      return `${isInUserProfile && isPurchased ? `You own it` : `From ${format.price.withCurrency(price.price, price.currency)}`}`;
    }
    return `${isInUserProfile ? 'Free' : 'Free'}`;
  };

  return (
    <m.div
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      whileTap={{ scale: 1 }}
      onTapStart={() => setIsPressed(true)}
      onTapCancel={() => setIsPressed(false)}
      className={classNames(s.motion, className)}
    >
      <Link className={inContainer ? s.noBoundaries : s.withShadow} to={link || '#'} newTab={newTab}>
        <div className={s.imageContainer}>
          {image && (
            <m.div animate={inContainer && { scale: (isPressed && 1) || (isHovered ? 1.04 : 1), transition: { duration: 0.3 } }}>
              <Image src={image} layout="responsive" objectFit="cover" alt="" width={240} height={imageHeight} />
            </m.div>
          )}
          {type === CardType.Broadcast && (
            <div className={s.playWrapper}>
              <div className={s.playCircle}>
                <Play weight="fill" />
              </div>
            </div>
          )}
        </div>
        <div className={s.labels}>
          {category && <p className={s.labelsCategory}>{category}</p>}
          <h2 className="body-md-bold">
            <m.span animate={{ opacity: isHovered ? 0.7 : 1 }}>{title} </m.span>
            {isNew && <div className="caption">New</div>}
            {isFree && <div className="caption">Free</div>}
          </h2>
          {assets && type === CardType.Plan && assets.length > 0 && (
            <div className={s.assets}>
              {assets.map(i => {
                const backgroundColor = (i.type === 'audio' && 'var(--color-success)')
                  || (i.type === 'video' && 'var(--color-warning)')
                  || (i.type === 'links' && 'var(--color-noodle)')
                  || 'var(--color-noodle)';

                const Icon: IconType | undefined = (i.type === 'audio' && Microphone) || (i.type === 'video' && VideoCamera) || (i.type === 'links' && LinkIcon) || undefined;

                return (
                  <div key={i.type}>
                    {Icon && (
                      <div className={s.icon} style={{ backgroundColor }}>
                        <Icon size={10} color="var(--color-gray-0)" weight="fill" />
                      </div>
                    )}
                    <p className="caption">
                      {i.count || i.minutes} {i.type === 'video' || i.type === 'audio' ? 'min' : 'links'}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          {type === CardType.Plan && (price || price === 0 || steps || tags) && (
            <>
              <div className={s.assets}>
                <p className="caption">
                  {priceLabel()}
                  {steps && `${price ? ' ·' : ''} ${steps} step${steps > 1 ? 's' : ''}`}
                </p>
              </div>
              {tags && tags.length > 1 && (
                <span className="caption" style={{ color: 'var(--color-gray-100)', fontWeight: 600 }}>
                  {tags.join(', ')}
                </span>
              )}
            </>
          )}
          {type === CardType.Broadcast && (
            <p className={`${s.assets} caption`}>
              {duration}
              {timestamp && ` · ${new Date(timestamp).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}`}
            </p>
          )}
          {participants && <CommentParticipants participants={participants} userLogoSize={28} maxLength={2} />}
        </div>
      </Link>
    </m.div>
  );
};

export default Card;
