import React, { useState } from 'react';
import * as ApiModels from '@typings/api-models';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import CaretCircleDown from '@components/Icons/CaretCircleDown';
import Image from '@components/Image';
import { useRouter } from 'next/router';
import ProgressIndicator from '@components/ProgressIndicator';
import EditButton from '@components/EditButton';
import PlusCircle from '@components/Icons/PlusCircle';
import Buttons from '@components/Buttons';
import s from './ProductTypeContainer.module.scss';

type ThisProduct = Pick<ApiModels.Product, 'id' | 'slug' | 'title' | 'isActive'> & {
  handbookSteps: Array<Pick<ApiModels.HandbookStep, 'id'>>;
};

type ContainerProps = {
  type: Pick<ApiModels.ProductType, 'title'> & {
    thumbnail?: Pick<ApiModels.Asset, 'url'> | null;
  };
  bytes: Array<ThisProduct>;
  creatorSlug: string;
  canEdit?: boolean;
  onEditCollection?: (isOpen?: boolean) => void;
  isCollection?: boolean;
  inactive?: boolean;
  setIsCreateServiceModalOpen?: (isOpen: boolean) => void;
};

const ProductTypeContainer = ({
  bytes,
  creatorSlug,
  type,
  canEdit,
  onEditCollection,
  isCollection,
  inactive,
  setIsCreateServiceModalOpen,
}: ContainerProps): JSX.Element => {
  const router = useRouter();
  const [disabledSlug, setDisabledSlug] = useState<string | undefined>(undefined);
  const [selectedProductId, setProductId] = useState<string>('');

  const handleContentClick = (productSlug: string): void => {
    setDisabledSlug(productSlug);
    router.push(getUrl(IDENTIFIERS.PRODUCT, { creatorSlug, productSlug }));
  };
  const handleEditButton = (productSlug: string, pId: string): void => {
    if (disabledSlug) return;
    setDisabledSlug(productSlug);
    setProductId(pId);
    router.push(getUrl(IDENTIFIERS.PRODUCT, { byteModal: true, creatorSlug, productSlug }));
  };

  const bytesToShow = bytes.filter(b => (!inactive ? b.isActive : !b.isActive));

  if (!bytesToShow.length) return <></>;
  return (
    <>
      <details
        className={s.productType}
        key={type?.title}
      >
        <summary>
          <div>
            {type?.thumbnail?.url && <Image image={type.thumbnail.url} alt="" />}
            <div>
              <h2>{type?.title}</h2>
              <div className={s['productType__byte-details']}>
                {onEditCollection && (
                  <button
                    className={s['productType__byte-details__edit']}
                    onClick={e => {
                      e.preventDefault();
                      onEditCollection();
                    }}
                  >
                    Edit
                  </button>
                )}
                <small>
                  {bytesToShow.length} {inactive && 'inactive'} byte{(bytesToShow.length > 1 && 's') || ''}
                </small>
              </div>
            </div>
          </div>
          <CaretCircleDown weight="fill" />
        </summary>
        <ul>
          {bytesToShow.map(product => (
            <li key={product.id}>
              <button disabled={disabledSlug === product.slug} onClick={() => handleContentClick(product.slug)}>
                {(disabledSlug === product.slug || selectedProductId === product.id) && <ProgressIndicator key={product.id} size={12} />}
                <div className={s['byte-content']}>
                  <p style={((disabledSlug === product.slug || !product.isActive) && { color: 'var(--color-gray-75)' }) || {}}>
                    {product.title}
                    {product.handbookSteps.length > 1 && (
                      <>
                        <small> · </small>
                        <small>{product.handbookSteps.length} steps</small>
                      </>
                    )}
                  </p>
                </div>
              </button>
              {canEdit && <EditButton label="Edit" onClick={() => handleEditButton(product.slug, product.id)} />}
            </li>
          ))}
          {isCollection && (
            <Buttons
              isWrapper
              className={s['add-byte']}
              onClick={() => {
                if (setIsCreateServiceModalOpen) {
                  setIsCreateServiceModalOpen(true);
                }
              }}
            >
              <PlusCircle weight="fill" size={20} />
              <p>Create byte</p>
            </Buttons>
          )}
        </ul>
      </details>
    </>
  );
};

export default ProductTypeContainer;
