import * as React from 'react';
import type { Icon } from '@phosphor-icons/react';
import s from './FilterChip.module.scss';

type Props = {
  label: string;
  number?: number;
  isSelected: boolean;
  hasLogo?: boolean;
  onClick: () => void;
  Icon?: Icon;
};

const shortenNumber = (num: number): string => {
  if (num < 1000) {
    return num.toString();
  }
  if (num < 1000000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return `${(num / 1000000).toFixed(1)}M`;
};

const FilterChip: React.FC<Props> = ({ label, number = 0, isSelected, Icon, onClick }) => (
  <button className={s.chipButton} onClick={onClick}>
    <div className={isSelected ? s.chipSelected : s.chip}>
      {Icon && <Icon size={16} />}
      {label} {number > 0 && <span className={s.number}>{shortenNumber(number)}</span>}
    </div>
  </button>
);

export default FilterChip;
