const SvgSpotifyLogo: React.FC = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 0C5.87262 0 0.5 5.37272 0.5 12C0.5 18.6278 5.87272 24 12.5 24C19.1278 24 24.5 18.6278 24.5 12C24.5 5.37319 19.1278 0.00065625 12.4998 0.00065625L12.4999 9.375e-05L12.5 0ZM18.003 17.3076C17.7881 17.6601 17.3266 17.7718 16.9741 17.5554C14.1567 15.8345 10.6099 15.4447 6.43288 16.399C6.23945 16.443 6.03646 16.4085 5.86854 16.3028C5.70061 16.1972 5.58149 16.0293 5.53737 15.8359C5.51539 15.7401 5.51251 15.641 5.52889 15.5441C5.54527 15.4473 5.5806 15.3546 5.63285 15.2714C5.6851 15.1882 5.75324 15.1161 5.83338 15.0593C5.91352 15.0025 6.00408 14.9621 6.09987 14.9403C10.6711 13.8959 14.592 14.3455 17.7552 16.2787C18.1077 16.495 18.2194 16.9551 18.003 17.3076ZM19.4718 14.0401C19.2009 14.4803 18.6249 14.6193 18.185 14.3484C14.9594 12.3658 10.0425 11.7916 6.22738 12.9497C5.73256 13.0991 5.21 12.8203 5.05981 12.3263C4.91075 11.8315 5.18975 11.3099 5.68372 11.1595C10.0417 9.83709 15.4595 10.4777 19.1637 12.7539C19.6036 13.0249 19.7427 13.6009 19.4718 14.0402V14.0401ZM19.5979 10.6376C15.7303 8.34037 9.34944 8.12906 5.65691 9.24994C5.06394 9.42975 4.43684 9.09497 4.25722 8.502C4.0775 7.90875 4.41191 7.28212 5.00534 7.10184C9.24406 5.81503 16.2907 6.06375 20.7433 8.70712C21.2779 9.02363 21.4527 9.7125 21.136 10.2451C20.8208 10.7785 20.13 10.9543 19.5985 10.6376H19.5979Z"
      fill="#1ED760"
    />
  </svg>
);

export default SvgSpotifyLogo;
