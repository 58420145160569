import classNames from 'classnames';
import Buttons from '@components/Buttons';
import PlusCircle from '@components/Icons/PlusCircle';
import PaperPlaneRight from '@components/Icons/PaperPlaneRight';
import s from './StartPostButton.module.scss';

type Props = {
  onClick: () => void;
};

const StartPostButton: React.FC<Props> = ({ onClick }) => (
  <Buttons isWrapper onClick={onClick} className={s.sharePost__wrapper}>
    <PlusCircle weight='fill' size={24} color='var(--color-primary)'/>
    <span className={classNames(s.text, 'body-sm')}>Start a post</span>
    <PaperPlaneRight size={24} className={s.sendIcon} weight="fill" color='var(--color-gray-25)'/>
  </Buttons>
);

export default StartPostButton;