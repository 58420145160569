import { FC, ReactElement } from 'react';
import Buttons from '@components/Buttons';
import s from './Banner.module.scss';

type Props = {
  isFetching?: boolean;
  onClick: () => void;
  text: string;
  icon?: ReactElement;
};

const Banner: FC<Props> = ({ isFetching, onClick, text = true, icon }) => (
  <div className={s.sticky}>
    <Buttons
      isSecondary
      isFullWidth
      isLarge
      disabled={false}
      isFetching={isFetching}
      onClick={onClick}
      className={s.banner}
      iconBefore={icon}
    >
      {text}
    </Buttons>
  </div>
);

export default Banner;
