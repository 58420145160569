import React, { FC, useEffect, useState } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import { updateCreatorHeroLinks } from '@tsClient';
import Buttons from '@components/Buttons';
import TrashSimple from '@components/Icons/TrashSimple';
import InputField from '@components/FormFields/InputField';
import HeroIcon from '@lib/CreatorBySlug/HeroLinks/HeroIcon';
import CustomSelect from '@components/FormFields/CustomSelect';
import { ContentLibraryTypes, heroLinksAvailableTypes } from '@typings/graphql-models';
import { useToast } from '@hooks';
import { ValidationErrors } from '@helpers/validation';
import { nanoid } from 'nanoid';
import { PlusCircle } from '@phosphor-icons/react';
import s from './EditHeroLinksForm.module.scss';

export type TypeHeroLink = {
  nanoId?: string;
  id?: string | null;
  url?: string | null;
  title?: string | null;
  type?: ContentLibraryTypes | null;
};

type Props = {
  heroLinks: TypeHeroLink[];
  creatorSlug: string;
  afterSave: () => void;
};

const emptyLink = { title: ContentLibraryTypes.Other, type: ContentLibraryTypes.Other, url: '' };

const RULES = {
  title: {
    isRequired: true,
    minLength: 3,
  },
  url: {
    isRequired: true,
    isUrl: true,
  },
};

const EditHeroLinksForm: FC<Props> = ({ heroLinks, creatorSlug, afterSave }) => {
  type FormErrors = ValidationErrors<TypeHeroLink>;
  const addToast = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [linksToDisplay, setLinksToDisplay] = useState<typeof heroLinks>([]);
  const [validationErrors, setValidationErrors] = useState<FormErrors[]>([]);
  const { getData: updateCreatorHeroLinksFn } = useNoodleApi(updateCreatorHeroLinks);

  useEffect(() => {
    const nanoId = nanoid();
    if (heroLinks.length === 0) {
      setLinksToDisplay([{ ...emptyLink, nanoId }]);
    } else {
      setLinksToDisplay([...heroLinks]);
    }
  }, [heroLinks]);

  const handleEditLink = (indexToEdit: number, field: keyof TypeHeroLink) => (value: string | ContentLibraryTypes, options?: object) => {
    setLinksToDisplay(prevValue =>
      prevValue.map((link, index) => {
        if (index !== indexToEdit) {
          return link;
        }
        const newLink = { ...link };
        if (field === 'type') {
          newLink[field] = value as ContentLibraryTypes;
        } else {
          newLink[field] = value;
        }
        return newLink;
      }),
    );
    if (options) {
      const { formErrors } = options as unknown as { formErrors: FormErrors };
      setValidationErrors(prevValue => {
        const newErrors = { ...(prevValue[indexToEdit] || {}) };
        newErrors[field] = formErrors[field];
        if (prevValue.length >= indexToEdit + 1) {
          return prevValue.map((e, i) => (i !== indexToEdit ? (field === 'url' ? {} : e) : newErrors));
        }

        return [...prevValue, newErrors];
      });
    }
  };

  const handleAddLink = (): void => {
    setLinksToDisplay(prevValue => [...prevValue, { ...emptyLink, nanoId: nanoid() }]);
  };

  const handleRemoveLink = (indexToRemove: number) => () => {
    setLinksToDisplay(prevLinks => {
      const newLinks = [...prevLinks];
      newLinks.splice(indexToRemove, 1);
      return newLinks;
    });
  };

  const handleSave = async (): Promise<void> => {
    setIsSaving(true);
    const { error } = await updateCreatorHeroLinksFn({ body: linksToDisplay, creatorSlug });
    if (!error) {
      setTimeout(() => {
        setIsSaving(false);
        afterSave?.();
      }, 1000);
    } else {
      addToast(useToast.ToastTypeVariants.ERROR, error.message);
    }
  };

  const isAddDisabled = linksToDisplay.some(l => !l.title || !l.type || !l.url) || linksToDisplay.length > 4;
  const isSaveDisabled = linksToDisplay.filter(l => !(!l.title && !l.type && !l.url)).some(l => !l.title || !l.type || !l.url)
    || validationErrors.some(r => Object.values(r).some(e => !!e));

  return (
    <div>
      <div className={s.links}>
        {linksToDisplay.map((link, index) => (
          <div key={link.id || link.nanoId} className={s.item}>
            <div className={s.itemType}>
              <CustomSelect
                label={'Type'}
                id={`type__${index}`}
                name={'type'}
                onChange={value => {
                  handleEditLink(index, 'type')(value as ContentLibraryTypes);
                  handleEditLink(index, 'title')(value as ContentLibraryTypes);
                }}
                options={heroLinksAvailableTypes.map(l => ({ title: l, value: l }))}
                value={linksToDisplay[index].type || ''}
                error={validationErrors[index]?.title || ''}
              />
              <Buttons
                icon={
                  <TrashSimple color="var(--color-error)" weight="fill" />
                }
                onClick={handleRemoveLink(index)}
              />
            </div>
            <div className={s.input}>
              <div style={{ left: 12, position: 'absolute', top: 36, zIndex: 1 }}>
                <HeroIcon mediaType={link.type || ''} />
              </div>
              <InputField
                onChange={handleEditLink(index, 'url')}
                id={`url__${index}`}
                name={`url`}
                label="Url"
                values={{ url: linksToDisplay[index].url }}
                rules={RULES}
                hasFixedHeight
                formErrors={validationErrors[index]}
              />
            </div>
          </div>
        ))}
      </div>
      <Buttons disabled={isAddDisabled} isThird isFullWidth iconBefore={<PlusCircle weight='fill'/>} onClick={handleAddLink}>
        Add new link
      </Buttons>

      <div className={s.actions}>
        <Buttons isSecondary isFullWidth onClick={handleSave} disabled={isSaveDisabled} isShimmering={isSaving}>
          Save
        </Buttons>
      </div>
    </div>
  );
};

export default EditHeroLinksForm;
