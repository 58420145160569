import React, { FC } from 'react';

import Modal from '@components/Modal';
import { useIsMobile } from '@hooks';
import EditHeroLinksForm from '@components/EditHeroLinksForm';
import type { TypeHeroLink } from '@components/EditHeroLinksForm/EditHeroLinksForm';

import s from './HeroLinks.module.scss';

type Props = {
  heroLinks: TypeHeroLink[];
  creatorSlug: string;
  onClose: () => void;
  afterSave: () => void;
};

const EditHeroLinksModal: FC<Props> = ({ heroLinks, creatorSlug, onClose, afterSave }) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      title={'Edit profile links'}
      containerStyle={isMobile ? {} : { width: '80%' }}
      onClose={onClose}
    >
      <div className={s.wrapper}>
        <EditHeroLinksForm heroLinks={heroLinks} creatorSlug={creatorSlug} afterSave={afterSave}/>
      </div>
    </Modal>
  );
};

export default EditHeroLinksModal;
