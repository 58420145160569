import React, { useMemo, useState } from 'react';
import CaretCircleRight from '@components/Icons/CaretCircleRight';
import Link from '@components/CustomLink';
import Button from '@components/Buttons';
import { useRouter } from 'next/router';
import { getUrl, IDENTIFIERS } from 'helpers/urlsHelper';
import s from './BroadcastContainer.module.scss';
import BroadcastPreview from '../BroadcastPreview';

type BroadcastPreviewProps = Parameters<typeof BroadcastPreview>[0] & { id: string };
type BroadcastContainerProps = {
  data: BroadcastPreviewProps[];
  title?: string | null;
  allBroadcastLink?: string;
  howMany?: number;
  creatorSlug: string;
  isList?: boolean;
  shouldNavigate?: boolean;
};

const BroadcastContainer = ({
  data,
  title,
  allBroadcastLink,
  howMany,
  creatorSlug,
  isList,
  shouldNavigate = true,
}: BroadcastContainerProps): JSX.Element => {
  const router = useRouter();
  const [numItemsToDisplay, setNumItemsToDisplay] = useState(howMany || 4);
  const itemsToDisplay = useMemo(() => data.slice(0, numItemsToDisplay), [data, numItemsToDisplay]);

  const handleShowMore = (): void => {
    if (shouldNavigate) {
      router.push(getUrl(IDENTIFIERS.CREATOR, { creatorSlug, tab: 'Broadcasts' }));
    } else {
      setNumItemsToDisplay(prev => (prev || 0) + (howMany || 4));
    }
  };

  return (
    <section className={isList ? s.wrapperList : s.wrapper}>
      {!!allBroadcastLink && !isList && (
        <Link to={allBroadcastLink} prefetch={false}>
          <header style={{ cursor: 'pointer' }}>
            <h2 className="caps">{`Latest ${title}` ?? ''}</h2>
            <CaretCircleRight weight="fill" />
          </header>
        </Link>
      )}
      {!allBroadcastLink && title && (
        <header>
          <h2 className="caps">{title}</h2>
        </header>
      )}

      {itemsToDisplay?.map(item => <BroadcastPreview {...item} key={item.id} />)}

      {howMany && itemsToDisplay.length < data.length && (
        <Button isSecondary isFullWidth onClick={handleShowMore} className={s.button}>
          Show more
        </Button>
      )}
    </section>
  );
};

export default BroadcastContainer;
