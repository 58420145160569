const SvgDeezerLogo: React.FC = () => (
  <svg width="28" height="28" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_496_1652)">
      <path d="M24.4999 4V7.10106H19.3115V4H24.4999Z" fill="#40AB5D"/>
      <path d="M24.4999 8.29785V11.3989H19.3115V8.29785H24.4999V8.29785Z" fill="url(#paint0_linear_496_1652)"/>
      <path d="M24.4999 12.5986V15.6997H19.3115V12.5986H24.4999Z" fill="url(#paint1_linear_496_1652)"/>
      <path d="M5.68842 16.8994V20.0005H0.5V16.8994H5.68842Z" fill="url(#paint2_linear_496_1652)"/>
      <path d="M11.9567 16.8994V20.0005H6.76587V16.8994H11.9567Z" fill="url(#paint3_linear_496_1652)"/>
      <path d="M18.234 16.8994V20.0005H13.0432V16.8994H18.234Z" fill="url(#paint4_linear_496_1652)"/>
      <path d="M24.4999 16.8994V20.0005H19.3115V16.8994H24.4999Z" fill="url(#paint5_linear_496_1652)"/>
      <path d="M18.234 12.5986V15.6997H13.0432V12.5986H18.234Z" fill="url(#paint6_linear_496_1652)"/>
      <path d="M11.9567 12.5986V15.6997H6.76587V12.5986H11.9567Z" fill="url(#paint7_linear_496_1652)"/>
      <path d="M11.9567 8.29785V11.3989H6.76587V8.29785H11.9567V8.29785Z" fill="url(#paint8_linear_496_1652)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_496_1652" x1="21.9316" y1="11.4279" x2="21.8798" y2="8.25206" gradientUnits="userSpaceOnUse">
        <stop stopColor="#358C7B"/>
        <stop offset="0.5256" stopColor="#33A65E"/>
      </linearGradient>
      <linearGradient id="paint1_linear_496_1652" x1="19.2334" y1="15.5403" x2="24.6239" y2="12.8527" gradientUnits="userSpaceOnUse">
        <stop stopColor="#222B90"/>
        <stop offset="1" stopColor="#367B99"/>
      </linearGradient>
      <linearGradient id="paint2_linear_496_1652" x1="0.5" y1="18.4507" x2="5.68603" y2="18.4507" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9900"/>
        <stop offset="1" stopColor="#FF8000"/>
      </linearGradient>
      <linearGradient id="paint3_linear_496_1652" x1="6.7729" y1="18.4507" x2="11.9566" y2="18.4507" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF8000"/>
        <stop offset="1" stopColor="#CC1953"/>
      </linearGradient>
      <linearGradient id="paint4_linear_496_1652" x1="13.0433" y1="18.4507" x2="18.227" y2="18.4507" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CC1953"/>
        <stop offset="1" stopColor="#241284"/>
      </linearGradient>
      <linearGradient id="paint5_linear_496_1652" x1="19.3067" y1="18.4507" x2="24.4927" y2="18.4507" gradientUnits="userSpaceOnUse">
        <stop stopColor="#222B90"/>
        <stop offset="1" stopColor="#3559A6"/>
      </linearGradient>
      <linearGradient id="paint6_linear_496_1652" x1="12.8377" y1="14.9853" x2="18.4508" y2="13.3794" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CC1953"/>
        <stop offset="1" stopColor="#241284"/>
      </linearGradient>
      <linearGradient id="paint7_linear_496_1652" x1="6.58625" y1="13.1174" x2="12.168" y2="15.1071" gradientUnits="userSpaceOnUse">
        <stop offset="0.0026698" stopColor="#FFCC00"/>
        <stop offset="0.9999" stopColor="#CE1938"/>
      </linearGradient>
      <linearGradient id="paint8_linear_496_1652" x1="7.20763" y1="7.81763" x2="11.6058" y2="11.7886" gradientUnits="userSpaceOnUse">
        <stop offset="0.0026698" stopColor="#FFD100"/>
        <stop offset="1" stopColor="#FD5A22"/>
      </linearGradient>
      <clipPath id="clip0_496_1652">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
);

export default SvgDeezerLogo;
