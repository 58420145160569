import React, { useEffect, useState } from 'react';

import useNoodleApi from '@hooks/useNoodleApi';
import EditButton from '@components/EditButton';
import { getCreatorHeroLinks } from '@tsClient';
import { heroLinksAvailableTypes } from '@typings/graphql-models';
import {m} from 'framer-motion';
import EditHeroLinksModal from './EditHeroLinksModal';
import HeroIcon from './HeroIcon';

import s from './HeroLinks.module.scss';

type Props = {
  creatorSlug: string;
  canEdit?: boolean;
};

const HeroLinks: React.FC<Props> = ({ creatorSlug, canEdit }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { data: contentLibraries, getData: getCreatorHeroLinksFn } = useNoodleApi(getCreatorHeroLinks);
  const heroLinks = contentLibraries?.filter(contentLibrary => contentLibrary.type && heroLinksAvailableTypes.includes(contentLibrary.type));

  const getData = (): void => {
    getCreatorHeroLinksFn({ creatorSlug });
  };

  useEffect(() => {
    getCreatorHeroLinksFn({ creatorSlug });
  }, [getCreatorHeroLinksFn, creatorSlug]);

  return (
    <>
      {heroLinks && heroLinks.length > 0 && (
        <div className={s['hero-links']}>
          {heroLinks?.map((contentLibrary, index) => (
            <m.a initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.1 * index } }} key={contentLibrary.id} href={contentLibrary.url || ''} aria-label={contentLibrary.type || ''}>
              <HeroIcon mediaType={contentLibrary.type || ''} />
            </m.a>
          ))}
        </div>
      )}
      {canEdit && <EditButton onClick={() => setIsEditModalOpen(true)} label="Edit profile links" />}
      {canEdit && isEditModalOpen && <EditHeroLinksModal
        onClose={() => { setIsEditModalOpen(false); }}
        afterSave={() => { getData(); setIsEditModalOpen(false); }}
        creatorSlug={creatorSlug}
        heroLinks={heroLinks || []}
      />}
    </>
  );
};

export default HeroLinks;
