import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import useNoodleApi from '@hooks/useNoodleApi';

import * as tsClient from '@tsClient';
import { mixpanelTrack } from '@providers/Mixpanel';
import { useToast, useUser } from '@hooks';
import { getCookiesAsJson, useIsUserInitialized } from '@providers/Auth';
import { Descendant } from 'slate';
import useSocketContext from '@providers/Socket/useSocketContext';
import { SocketMessageType } from '@providers/Socket/SocketContext';
import { NewChatMessageData } from '@providers/Socket/typings';
import { ToastTypeVariants } from '@context/ToastContext';
import { isInPopoverWidget } from '@helpers/helper';
import { IframeMessageTypes } from '@helpers/iframeMessages';
import { JWT_COOKIE_NAME, NOODLE_API_HOST } from '@configuration/client';
import TeamsContext from '@providers/Teams/TeamsContext';
import * as ApiModels from '@typings/api-models';
import ChatWithExpert from '@/lib/ChatWithExpert';

const MESSAGES_PAGE_SIZE = 10;

type Props = {
  isAnonymous?: boolean;
  creatorSlug: string;
  teamCreatorId?: string | null;
  isEmbedded?: boolean;
  isOnUserProfile?: boolean;
};

type Creator = {
  id: string;
  isAiEnabled: boolean;
  slug: string;
  countryCode?: string | null;
  person?: {
    id: string;
    image: {
      url: string;
    };
  } | null;
  lastSeen?: string | null;
  name?: string | null;
};

type Child = {
  id: string;
  createdAt: string;
  isDeleted: boolean;
  title: string | null;
  text: string | null;
  isAIGenerated: boolean;
  owner?: {
    id: string;
    name?: string | null;
    image?: {
      url: string;
    } | null;
  } | null;
};

type ThisParticipant =
  | {
      id: string;
      name?: string | null;
      image?: {
        url: string;
      } | null;
      primaryColour?: {
        hex: string;
      } | null;
    }
  | undefined;

let resolveConversationSid: (val: { rootMessageId: string; conversationSid: string }) => void;
const conversationSidPromise = new Promise<{ rootMessageId: string; conversationSid: string }>(resolve => {
  resolveConversationSid = resolve;
});

let resolveUserId: (val: string) => void;
const userIdPromise = new Promise<string>(resolve => {
  resolveUserId = resolve;
});

const NewConversationWithCreator = ({ teamCreatorId, creatorSlug, isEmbedded, isAnonymous = false, isOnUserProfile }: Props): ReactElement | null => {
  const [conversationSid, setConversationSid] = useState<string | null>(null);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [rootMessageId, setRootMessageId] = useState<string | null>(null);
  const [creatorPersonId, setCreatorPersonId] = useState<string | null>(null);
  const [customer, setCustomer] = useState<ThisParticipant>(undefined);
  const [participants, setParticipants] = useState<ThisParticipant[]>([]);
  const [creator, setCreator] = useState<Creator | null>(null);
  const [isPostingComment, setIsPostingComment] = useState<boolean>(false);
  const [isAIEnabled, setAIEnabled] = useState(false);
  const [isAITyping, setIsAITyping] = useState(false);
  const [isPaginationEnabled, setPaginationEnabled] = useState(false);
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [isAnonymousChat] = useState(isAnonymous);
  const { getData: getCreatorFn } = useNoodleApi(tsClient.getCreator);
  const { getData: getConversationMessagesFn } = useNoodleApi(tsClient.messages.getConversationMessages);
  const { getData: postConversationMessageFn } = useNoodleApi(tsClient.conversations.postConversationMessage, {
    healthMonitor: { name: 'send-message' },
  });
  const { data: conversationPurchases, getData: getConversationPurchasesFn } = useNoodleApi(tsClient.conversations.getConversationPurchases);
  const { getData: setAIEnabledOnConversationFn } = useNoodleApi(tsClient.conversations.setAIEnabledOnConversation);
  const { getData: getChatMessageFn } = useNoodleApi(tsClient.messages.getConversationMessage);
  const { getData: findOrCreateConversationWithCreator } = useNoodleApi(tsClient.my.findOrCreateConversationWithCreator);
  const { getData: findOrCreateAnonymousConversationWithCreator } = useNoodleApi(tsClient.findOrCreateAnonymousConversationWithCreator);
  const { getData: createAnonymousUserFn } = useNoodleApi(tsClient.auth.createAnonymousUser, {
    toastErrorMessage: () => [ToastTypeVariants.ERROR, 'Failed to create user. Please try again or contact support.'],
  });
  const { getData: createEmptyAIMessage } = useNoodleApi(tsClient.createEmptyAIMessage);
  const [user, _setUser, setUserByToken] = useUser();
  const addToast = useToast();
  const userId = user?.id;
  const { creatorId, teamUserId } = useContext(TeamsContext);
  const isCreator = creatorId === creator?.id;
  const sendAsCreator = !!teamUserId && teamUserId === creatorPersonId;
  const userIsInitialized = useIsUserInitialized();
  const [children, setChildren] = useState<Parameters<typeof ChatWithExpert>[0]['messageData']>([]);
  const currentPageRef = useRef(1); // use ref instead of state to avoid potential races with multiple clicks.
  const { addListener, removeListener, isInitialized: isSocketInitialized, joinChannels } = useSocketContext();

  useEffect(() => {
    if (conversationSid) {
      mixpanelTrack('Viewed conversation', {
        conversationId: conversationSid,
      });
    }
  }, [conversationSid]);

  useEffect(() => {
    if (userId) {
      resolveUserId(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (isSocketInitialized && conversationId) {
      joinChannels([conversationId]);
    }
  }, [joinChannels, isSocketInitialized, conversationId]);

  useEffect(() => {
    const findOrCreateConversation = async (): Promise<void> => {
      if (user?.id && creator?.slug) {
        const createdConversationResponse = user.isAnonymous
          ? await findOrCreateAnonymousConversationWithCreator({ creatorSlug: creator.slug })
          : await findOrCreateConversationWithCreator({ creatorId: teamCreatorId, creatorSlug: creator.slug });
        if (createdConversationResponse.data) {
          setAIEnabled(createdConversationResponse.data.isAiEnabled || false);
          setConversationSid(createdConversationResponse.data.sid);
          setConversationId(createdConversationResponse.data.id);
          setRootMessageId(createdConversationResponse.data.rootMessageId || null);
          setParticipants(createdConversationResponse.data.participants);
        } else {
          addToast(ToastTypeVariants.ERROR, 'Failed to get conversation. Please try again or contact support.');
        }
      }
    };
    findOrCreateConversation();
  }, [creator, user, findOrCreateAnonymousConversationWithCreator, findOrCreateConversationWithCreator, addToast]);

  const createPerson = async (): Promise<void> => {
    const anonymousUserResponse = await createAnonymousUserFn();
    if (anonymousUserResponse.data?.token) {
      setUserByToken(anonymousUserResponse.data.token);
    }
  };

  useEffect(() => {
    if (!userId && userIsInitialized && !isAnonymousChat) {
      createPerson();
    }
  }, [userId, userIsInitialized, setUserByToken, createAnonymousUserFn, isAnonymousChat]);

  const refetchConversationPurchases = useCallback(async (): Promise<void> => {
    if (userId && conversationSid) {
      await getConversationPurchasesFn({ conversationSid });
    }
  }, [userId, conversationSid, getConversationPurchasesFn]);

  useEffect(() => {
    refetchConversationPurchases();
  }, [refetchConversationPurchases]);

  useEffect(() => {
    if (participants && participants.length > 0 && creatorPersonId) {
      setCustomer(participants.find(p => p?.id !== creatorPersonId));
    }
  }, [participants, creatorPersonId]);

  useEffect(() => {
    const fetchCreatorData = async (): Promise<void> => {
      const { data: fetchedCreator } = await getCreatorFn({ creatorSlug });
      setCreator(fetchedCreator);
      setCreatorPersonId(fetchedCreator?.person?.id || null);
    };
    fetchCreatorData();
  }, [creatorSlug, getCreatorFn]);

  const getAIText = async (messageId: string): Promise<void> => {
    const response = await fetch(`${NOODLE_API_HOST}/messages/${messageId}/ai-response`, {
      headers: {
        Authorization: getCookiesAsJson()[JWT_COOKIE_NAME] || window.httpToken || '',
      },
    });
    const reader = response.body?.getReader();
    if (reader) {
      let chunk;
      do {
        // eslint-disable-next-line no-await-in-loop
        chunk = await reader.read();
        const text = new TextDecoder().decode(chunk.value);
        if (text) {
          setIsAITyping(false);
          if (text.includes('"message":"Internal Server Error"') || text.includes('"message":"BadRequest"')) {
            setChildren(prev => prev.map((c) => ({
              ...c,
              text: c.id === messageId ? 'I\'m sorry, the AI assistant is having trouble right now. Please try sending another message.' : c.text,
            })));
          } else {
            setChildren(prev => prev.map((c) => ({
              ...c,
              text: c.id === messageId ? (c.text || '') + text : c.text,
            })));
          }
        }
      } while (!chunk?.done);
    }
  };

  const getAIMessage = async (): Promise<void> => {
    const { conversationSid: sid } = await conversationSidPromise;
    const loggedInUserId = await userIdPromise;
    if (sid && loggedInUserId) {
      setIsAITyping(true);
      const { data: message } = await createEmptyAIMessage({ conversationSid: sid });
      if (message) {
        setChildren(prev => [message, ...prev]);
        await getAIText(message.id);
      } else {
        setIsAITyping(false);
      }
    }
  };

  useEffect(() => {
    const handleNewComment = async (data: unknown): Promise<void> => {
      if (conversationSid) {
        const { authorId, messageId } = data as NewChatMessageData;
        if (authorId !== userId) {
          const { data: newMessage } = await getChatMessageFn({ conversationSid, messageId });
          if (newMessage) {
            setChildren(prev => [newMessage, ...prev]);
          }
        }
      }
    };
    const listenerId = addListener({
      fn: handleNewComment,
      messageType: SocketMessageType.NEW_CHAT_MESSAGE,
    });
    return () => {
      removeListener(listenerId);
    };
  }, [addListener, removeListener, userId, conversationSid, getChatMessageFn, isAIEnabled]);

  const handleAIEnabledChange = async (isEnabled: boolean): Promise<void> => {
    if (conversationSid) {
      setAIEnabled(isEnabled);
      if (creatorId) {
        await setAIEnabledOnConversationFn({ conversationSid, creatorId, isEnabled });
      }
    }
  };

  const refetchMessagesAfterSend = async ({
    attachments,
    text,
    medias,
    handbooks,
  }: {
    attachments?: ApiModels.CreateMessageAttachment[];
    text?: { children: Descendant[] }[];
    medias?: { id: string }[];
    handbooks?: { id: string }[];
  }): Promise<void> => {
    if (text?.length || medias?.length || handbooks?.length) {
      let isCreatingConversation = false;
      if (isAnonymousChat && !userId) {
        isCreatingConversation = true;
        await createPerson();
      }
      const { conversationSid: sid, rootMessageId: rMsgId } = await conversationSidPromise;
      setIsPostingComment(true);
      const newComment = await postConversationMessageFn({
        attachments,
        conversationSid: sid,
        handbooks,
        medias,
        messageId: rMsgId,
        messageType: ApiModels.MessageType.Chat,
        sendAsCreator,
        text: text ? { children: text } : null,
      });
      if (newComment.data) {
        // This is weird, but necessary to make typescript not complain. newComment.data should never be null per the check above...
        setChildren(prev => [newComment.data ? newComment.data : prev[0], ...prev]);
      }
      setIsPostingComment(false);
      if ((isAIEnabled && creator?.isAiEnabled) || (isCreatingConversation && creator?.isAiEnabled)) {
        getAIMessage();
      }
    }
  };

  const fetchNextPage = async (rootId: string, currentPage: number, currentMessages: Child[]): Promise<boolean> => {
    currentPageRef.current = currentPage + 1;
    const messageResponse = await getConversationMessagesFn({
      page: currentPageRef.current,
      perPage: MESSAGES_PAGE_SIZE,
      rootMessageId: rootId,
    });
    if (messageResponse.data) {
      const { children: newChildren } = messageResponse.data;
      const allChildren = uniqBy(currentMessages.concat(newChildren), 'id');
      mixpanelTrack('Next Chat Page', {
        isAtEnd: allChildren.length === currentMessages.length,
        numMessages: allChildren.length,
        page: currentPageRef.current,
        perPage: MESSAGES_PAGE_SIZE,
        rootId,
      });
      setChildren(allChildren);
      if (allChildren.length === currentMessages.length) {
        return true;
      }
    } else {
      mixpanelTrack('Next Chat Page Error', {
        numMessages: currentMessages.length,
        page: currentPageRef.current,
        perPage: MESSAGES_PAGE_SIZE,
        rootId,
      });
    }
    return false;
  };

  const initializeMessage = async (): Promise<void> => {
    if (rootMessageId) {
      const messageResponse = await getConversationMessagesFn({
        page: currentPageRef.current,
        perPage: MESSAGES_PAGE_SIZE,
        rootMessageId,
      });
      if (messageResponse.data) {
        const { children: newChildren } = messageResponse.data;
        if (newChildren.length === MESSAGES_PAGE_SIZE) {
          setPaginationEnabled(true);
        }
        setChildren(newChildren);
        setIsInitialFetch(false);
        if (isInPopoverWidget()) {
          window.parent.postMessage(
            {
              message: IframeMessageTypes.CONVERSATION_INITIALIZED,
              postedTo: window.name,
              value: {
                conversationSid,
                initialMessage: newChildren[0]?.text,
                isEmptyConversation: newChildren.length <= 1,
              },
            },
            '*',
          );
        }
      }
      if (conversationSid && rootMessageId) {
        resolveConversationSid({ conversationSid, rootMessageId });
      }
    }
  };
  const fetchInitialData = async (): Promise<void> => {
    if (!isAnonymousChat) {
      setChildren([]);
    }
    await initializeMessage();
  };

  useEffect(() => {
    if (userIsInitialized) {
      fetchInitialData();
    }
  }, [conversationSid, userId, userIsInitialized, rootMessageId, creatorPersonId, getConversationMessagesFn]);

  const removeDeletedMessages = (id: string): void => {
    setChildren(prev => prev.filter((m) => m.id !== id));
  };

  return (
    <ChatWithExpert
      isOnUserProfile={isOnUserProfile}
      conversationSid={conversationSid}
      currentPage={currentPageRef.current}
      rootMessageId={rootMessageId}
      messageData={children}
      creator={creator}
      customer={customer}
      onFetchNextPage={fetchNextPage}
      onSendMessage={refetchMessagesAfterSend}
      onDeleteMessage={removeDeletedMessages}
      isTheCreator={isCreator}
      isAllMessages={children.length < 1 ? false : children.length < MESSAGES_PAGE_SIZE}
      isPostingComment={isPostingComment}
      purchases={conversationPurchases}
      refetchPurchases={refetchConversationPurchases}
      isAIEnabled={isAIEnabled}
      setAIEnabled={handleAIEnabledChange}
      isEmbedded={isEmbedded}
      isAITyping={isAITyping}
      isPaginationEnabled={isPaginationEnabled}
      isInitialFetch={isInitialFetch}
      isAnonymousChat={isAnonymousChat}
      reloadConversation={fetchInitialData}
    />
  );
};

export default NewConversationWithCreator;
