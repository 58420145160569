type Owner = {
  id: string;
  name?: string | null;
  image?: { url: string } | null;
  primaryColour?: { hex: string } | null;
};

type ThisMessage = {
  children: Array<{
    owner?: Owner | null;
  }>
};

type Participant = {
  color?: string | null;
  id: string;
  logo?: string | null;
  name?: string | null;
};

const formatParticipants = (message: ThisMessage | null): Participant[] => {
  const namePictureMap: Record<string, Owner | null> = (message?.children || []).reduce((acc, val) => ({
    ...acc,
    [val.owner?.id || '']: val.owner || '',
  }), {}) || {};
  const participants = Object.keys(namePictureMap).map(id => ({
    color: namePictureMap[id]?.primaryColour?.hex || null,
    id,
    logo: namePictureMap[id]?.image?.url || null,
    name: namePictureMap[id]?.name || null,
  }));
  return participants;
};

export default formatParticipants;
