import React, { ReactElement, useEffect, useState } from 'react';

import s from './TextEllipsisDetails.module.scss';

type Props = {
  text: string;
};

function TextEllipsisDetails({ text }: Props): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);

  const pRef = React.useRef<HTMLParagraphElement>(null);

  // checks if it is clamped
  useEffect(() => {
    const handleResize = (): void => {
      if (pRef.current) {
        const { clientHeight, scrollHeight } = pRef.current;
        setIsClamped(clientHeight < scrollHeight);
      }
    };

    const handleLoad = (): void => {
      // waiting for the render to be completed
      setTimeout(() => {
        handleResize();
        setIsClamped((pRef.current?.clientHeight || 0) < (pRef.current?.scrollHeight || 0));
      }, 800);
    };

    handleLoad();
    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, [pRef]);

  return (
    <details onToggle={() => setIsExpanded(!isExpanded)} className={s.textEllipsis}>
      <p>{text}</p>
      <summary>
        <p ref={pRef} style={!isClamped ? { minHeight: 21 } : undefined}>{!isExpanded && text}</p>
        {isClamped && <strong>{isExpanded ? 'Collapse' : 'Read more'}</strong>}
      </summary>
    </details>
  );
}

export default TextEllipsisDetails;